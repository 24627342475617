import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-form-validator-core";

import "./blank_page_style.css";

import InputWithLabel from "../../Forms/Elements/InputWithLabel";

import { adminLogin, corporateForgotPassword, corporateLogin, resetPasswordLinkTokenData, resetPasswordLinkTokenError, resetUserData, resetUserErrorData, WorksiteForgotPassword } from "../../../api-actions/adminAuthActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import { postForgotPassword, resetPostForgotPasswordData, resetPostForgotPasswordError } from "../../../api-actions/mailDataActions";

const ForgotPassword = ({ }) => {
  const [simpleTabs, setSimpleTabs] = useState([false, false, true]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const url = useParams();

  const userRole = url['*'];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminResetToken = useSelector((state) => state.AdminAuthData.resetToken);
  const adminResetTokenError = useSelector((state) => state.AdminAuthData.resetTokenError);

  const postForgotPasswordData = useSelector((state) => state.MailData.postForgotPasswordData);
  const postForgotPasswordError = useSelector((state) => state.MailData.postForgotPasswordError);

  useEffect(() => {
    if (adminResetToken && Object.keys(adminResetToken)?.length > 0) {
      const userEmail = adminResetToken?.data?.passwordToken?.userEmail
      const content = {
        link: adminResetToken?.data?.resetPasswordLink,
        msg: 'We received a request to reset your password.',
        userRole: adminResetToken?.data?.passwordToken?.userRole,
      }
      const data = {userEmail, content}
      dispatch(postForgotPassword(data))
      dispatch(resetPasswordLinkTokenData())
    }
  }, [adminResetToken]);

  useEffect(() => {
    if (adminResetTokenError && Object.keys(adminResetTokenError)?.length > 0) {
      toast.error(adminResetTokenError?.message);
      dispatch(resetPasswordLinkTokenError());
    }
  }, [adminResetTokenError]);
  
  console.log('123456')
  
  useEffect(() => {
    if (postForgotPasswordData && Object.keys(postForgotPasswordData)?.length > 0) {
      dispatch(resetPostForgotPasswordData())
      toast.success('Password reset link has been sent to your email.')
      navigate('/login');
    }
  }, [postForgotPasswordData])
  
  useEffect(() => {
    if (postForgotPasswordError && Object.keys(postForgotPasswordError)?.length > 0) {
      toast.error('Error sending password reset link')
      dispatch(resetPostForgotPasswordError())
    }
  }, [postForgotPasswordError])


  let handleWorksiteFormSubmit = () => {
    //Call this function on form submit with no errors

    if (email !== "") {
      if (userRole == 'worksite/forgot-password') {
        dispatch(WorksiteForgotPassword({
          userEmail: email,
          userRole: 'manager'
        }))
      } else if (userRole == 'corporate/forgot-password') {
        dispatch(corporateForgotPassword({
          userEmail: email,
          userRole: 'corporate'
        }))
      }
    } else {
      toast.error('Please fill all required fields')
    }
  };

  return (
    <div className="login-wrapper ">
      <div className="bg-pic">
        <div className="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 m-b-20">
          <h1 className="semi-bold text-white">
            Real-time call logging
          </h1>
          <p className="small">
            Our advanced call record system simplifies employee attendance management. With just a phone call, your team can check in and out securely, while you gain valuable insights into work patterns and productivity.
          </p>
        </div>
      </div>
      <div className="login-container bg-white">
        <div className="p-l-50 p-r-50 p-t-50 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40 cusmobpad">
          <img
            src={process.env.PUBLIC_URL + '/assets/img/fmglogo.png'}
            alt="FM Guardian"
            data-src={process.env.PUBLIC_URL + '/assets/img/fmglogo.png'}
            data-src-retina="/assets/img/fmglogo.png"
            width={'75%'}
            className="mx-auto"
          />

          {/* <p className="mw-80 m-t-5">Sign in </p> */}

          <div className="card card-borderless m-t-25">

            <div className="tab-content">
              <div id="tab2Inspire" >
                <h2 className="p-t-10">
                  <span className="small">Forgot Password </span>
                  {/* <br /> Worksite */}
                </h2>
                <div>Enter the email associated with your account and we will you link to reset your password</div>
                <ValidatorForm
                  instantValidate={true}
                  onSubmit={handleWorksiteFormSubmit}
                  className="p-t-15"
                >
                  <div className="form-group  form-group-default required py-2">
                    <InputWithLabel
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      value={email}
                      className={'form-control'}
                      // label={'Login'}
                      placeholder="Enter Your Email"
                      require="true"
                    />
                  </div>

                  <div className="row">
                    <div className="col-md-12 d-flex justify-content-center">
                      <center>
                        <button
                          aria-label=""
                          className="btn btn-primary btn-lg m-t-10"
                          type="submit"
                        >
                          Forgot My Password
                        </button>
                      </center>
                    </div>
                  </div>
                  <div className="m-b-5 m-t-30">

                  </div>
                  {/* <div>
              <a href="javascript:void(0);" className="normal">
                Not a member yet? Signup now.
              </a>
            </div> */}
                </ValidatorForm>
              </div>
            </div>
          </div>




          <div className="pull-bottom sm-pull-bottom w-100">
            <div className="m-b-30 p-r-80 sm-m-t-20 sm-p-r-15 sm-p-b-20 clearfix">
              <div className="col-sm-9 no-padding m-t-10">
                <p className="small-text normal hint-text">
                  ©{(new Date().getFullYear())} FM Guardian. All Rights Reserved.<br />
                  <a href="javascript:void(0);">Cookie Policy</a>,{' '}
                  <a href="javascript:void(0);"> Privacy and Terms</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
