export const calculateTotalWorkHours = (records) => {
  const totalMinutes = records.reduce((acc, record) => {
    if (record?.workHours) {
      const [hours = 0, minutes = 0] = record.workHours.split('.').map(Number);
      return acc + hours * 60 + minutes;
    }
    return acc;
  }, 0);

  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  return `${totalHours}Hr ${remainingMinutes}Min`;
};


export const calculateTotalWorkers = (records) => {
  const uniqueWorkers = new Set();

  records.forEach(record => {
    if (record.workerName) {
      uniqueWorkers.add(record.workerName)
    }
  })
  return uniqueWorkers.size;
}

export const calculateTotalCheckIns = (records) => {
  return records.reduce((acc, record) => {
    if (record.checkIn) {
      acc += 1
    }
    return acc
  }, 0)
}

export const calculateTotalCheckOuts = (records) => {
  return records.reduce((acc, record) => {
    if (record.checkOut) {
      acc += 1
    }
    return acc
  }, 0)
}

