import React from 'react';

const Spinner = () => {
  return (
    <div className='d-flex align-items-center justify-content-center' style={{minHeight: '75px'}}>
      <span class="spinner-border"></span>
    </div>
  );
};

export default Spinner;
