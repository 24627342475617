import React, { useState, useEffect, useRef } from 'react';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import PageContainer from '../UIElements/Containers';

import { deleteWorkOrderNumber, editWorkOrderNumber, getAllWorkOrderNumber, postWorkOrderNumber, resetDeleteWorkOrderNumberData, resetDeleteWorkOrderNumberErrorData, resetEditWorkOrderNumberData, resetEditWorkOrderNumberErrorData, resetPostWorkOrderNumberData, resetPostWorkOrderNumberErrorData, resetWorkOrderErrorData } from '../../api-actions/adminDataActions';
import { useDispatch, useSelector } from 'react-redux';
import SlideUpModal from '../Modals/SlideUpModal';
import { toast } from 'react-toastify';
import Button from '../UIElements/Button/UtilsButton';
import GroupSelect from "../Forms/Elements/GroupSelect";
import { getAllWorkSites } from '../../api-actions/workSiteDataAction';
import { FiShare } from 'react-icons/fi';
import NoData from '../NoData/NoData';
import Loader from '../Loader/Loader';
import { Link } from 'react-router-dom';

const WorkOrder = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const dispatch = useDispatch();

  const workSitesData = useSelector((state) => state.WorkSiteData.workSitesData);
  const workSitesErrorData = useSelector((state) => state.WorkSiteData.getWorkSiteRecordsError);
  const workOrdersData = useSelector((state) => state.AdminData.workOrdersData);
  const workOdersErrorData = useSelector((state) => state.AdminData.getWorkOdersError);
  const postWorkOrderNumberData = useSelector((state) => state.AdminData.postWorkOrderNumberData);
  const postWorkOrderNumberError = useSelector((state) => state.AdminData.postWorkOrderNumberError);
  const editWorkOrderNumberData = useSelector((state) => state.AdminData.editWorkOrderNumberData);
  const editWorkOrderNumberError = useSelector((state) => state.AdminData.editWorkOrderNumberError);
  const deleteWorkOrderNumberData = useSelector((state) => state.AdminData.deleteWorkOrderNumberData);
  const deleteWorkOrderNumberError = useSelector((state) => state.AdminData.deleteWorkOrderNumberError);

  const [workOrderNumberWithIndex, setWorkOrderNumberWithIndex] = useState([]);
  const [workOrderNumber, setWorkOrderNumber] = useState('');
  const [worksiteId, setWorksiteId] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentWorkOrderNumberId, setCurrentWorkOrderNumberId] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [csvProps, setCsvProps] = useState(null);
  const [worksiteOptions, setWorksiteOptions] = useState([]);
  const [worksiteOptionsForFilter, setWorksiteOptionsForFilter] = useState([]);
  const csvPropsRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [exportWorkOrdersData, setExportWorkOrdersData] = useState()


  // useEffect(() => {
  //   if (csvPropsRef.current) {
  //     setCsvProps(csvPropsRef.current);
  //   }
  // }, [csvPropsRef.current]);

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="custom-dropdown-container">
      <select
        value={currSizePerPage}
        onChange={(e) => onSizePerPageChange(Number(e.target.value))}
        className="custom-form-control"
      >
        {options.map(option => (
          <option key={option.text} value={option.page}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
  
  const options = {
    sizePerPageRenderer
  }; 

  useEffect(() => {
    dispatch(getAllWorkOrderNumber(jwtToken));
  }, [])

  useEffect(() => {
    // const data = workOrdersData.map((workOrder, i) => (
    //   { ...workOrder, index: i + 1 }
    // ))
    // setWorkOrderNumberWithIndex(data)
    if (workOrdersData) {
      const data = workOrdersData.map((workOrder, i) => (
        { ...workOrder, index: i + 1 }
      ))
      if (worksiteId?.length > 0) {
        const filteredData = data?.filter((record) => record?.worksiteId === worksiteId).map((record, i) => ({ ...record, index: i + 1 }));
        setWorkOrderNumberWithIndex(filteredData);
      } else {
        setWorkOrderNumberWithIndex(data)
      }
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [workOrdersData, worksiteId]);

  useEffect(() => {
    dispatch(getAllWorkSites(jwtToken));
  }, [jwtToken]);

  useEffect(() => {
    if (workOdersErrorData && Object.keys(workOdersErrorData)?.length > 0) {
      dispatch(resetWorkOrderErrorData());
    }
  }, [workOdersErrorData]);

  useEffect(() => {
    if (postWorkOrderNumberData && Object.keys(postWorkOrderNumberData)?.length > 0) {
      setWorkOrderNumber('')
      toast.success('Work order number added successfully')
      setSlideUpVisible(false)
      dispatch(getAllWorkOrderNumber(jwtToken));
      dispatch(resetPostWorkOrderNumberData())
    }
  }, [postWorkOrderNumberData])

  useEffect(() => {
    if (postWorkOrderNumberError && Object.keys(postWorkOrderNumberError)?.length > 0) {
      toast.error(postWorkOrderNumberError.message)
      dispatch(resetPostWorkOrderNumberErrorData());
    }
  }, [postWorkOrderNumberError]);

  useEffect(() => {
    if (editWorkOrderNumberData && Object.keys(editWorkOrderNumberData)?.length > 0) {
      setWorkOrderNumber('');
      toast.success('WorkOrder number updated successfully');
      setSlideUpVisible(false);
      dispatch(getAllWorkOrderNumber(jwtToken));
      dispatch(resetEditWorkOrderNumberData());
    }
  }, [editWorkOrderNumberData]);

  useEffect(() => {
    if (editWorkOrderNumberError && Object.keys(editWorkOrderNumberError)?.length > 0) {
      toast.error(editWorkOrderNumberError.message);
      dispatch(resetEditWorkOrderNumberErrorData());
    }
  }, [editWorkOrderNumberError]);

  useEffect(() => {
    if (deleteWorkOrderNumberData && Object.keys(deleteWorkOrderNumberData)?.length > 0) {
      toast.success('WorkOrder number deleted successfully');
      dispatch(getAllWorkOrderNumber(jwtToken));
      dispatch(resetDeleteWorkOrderNumberData());
    }
  }, [deleteWorkOrderNumberData]);

  useEffect(() => {
    if (deleteWorkOrderNumberError && Object.keys(deleteWorkOrderNumberError)?.length > 0) {
      toast.error(deleteWorkOrderNumberError.message);
      dispatch(resetDeleteWorkOrderNumberErrorData());
    }
  }, [deleteWorkOrderNumberError]);

  useEffect(() => {
    if (workSitesData) {
      setWorksiteOptions([]);
      setWorksiteOptionsForFilter([]);
      const worksiteData = workSitesData?.map((record) => ({
        value: record?.id,
        label: record?.storeName,
      }));

      setWorksiteOptions(Array.from(new Map(worksiteData.map(item => [item.value, item])).values()))

      const uniqueArray = Array.from(new Map(worksiteData.map(item => [item.value, item])).values());
      if (uniqueArray?.length > 0) {
        uniqueArray.unshift({ value: '', label: 'All Stores' });
      }
      setWorksiteOptionsForFilter(uniqueArray);
    }

  }, [workSitesData]);

  useEffect(() => {
    setExportWorkOrdersData(nullToEmpty(workOrderNumberWithIndex))
  }, [workOrderNumberWithIndex]);

  const nullToEmpty = (data) => {
    return data?.map((item, i) => {
      let updatedItem = {};

      for (let key in item) {
        updatedItem[key] = item[key] === null ? "" : item[key];
        if ((key === 'createdAt') && updatedItem[key]) {
          updatedItem[key] = formatDate(updatedItem[key]);
        }
      }

      return {
        ...updatedItem,
        index: i + 1
      };
    });
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // 12-hour time format
    };

    // Format the date
    const formattedDate = date.toLocaleString('en-US', options);

    return formattedDate;
  };

  //slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([
    false,
    true,
    false,
  ]);
  const [slideUpWidth, setSlideUpWidth] = useState("500");
  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth("600");
    if (slideUpModalSize[1]) setSlideUpWidth("500");
    if (slideUpModalSize[2]) setSlideUpWidth("300");
  }, [slideUpModalSize]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    if (value === '') {
      setWorkOrderNumberWithIndex(workOrdersData.map((record, i) => ({ ...record, index: i + 1 })));
    } else {
      const filteredData = workOrdersData.filter(data => {
        return (
          data.workOrderNumber?.toLowerCase().includes(value.toLowerCase())
        );
      }).map((record, i) => ({ ...record, index: i + 1 }));
      setWorkOrderNumberWithIndex(filteredData);
    }
  };

  const handleInputChange = (e, selectOption = null) => {
    if (selectOption) {
      setWorkSiteId(selectOption)
    } else {
      const { name, value } = e.target;
      setWorkOrderNumber(value);
    }
  };


  const handleAddOrEdit = () => {
    const workOrderNumberRegex = /^\d+$/;
    if (workOrderNumber === '' || !workOrderNumberRegex.test(workOrderNumber)) {
      return toast.error('Please enter a valid work order number');
    }
    if (isEditMode) {
      dispatch(editWorkOrderNumber(jwtToken, currentWorkOrderNumberId, { workOrderNumber }));
    } else {
      dispatch(postWorkOrderNumber(jwtToken, { worksiteId, workOrderNumber }));
    }
  };

  const handleAddClick = () => {
    setIsEditMode(false);
    setWorkOrderNumber('');
    setSlideUpVisible(true);
  }

  const handleEditClick = (row) => {
    setIsEditMode(true)
    setCurrentWorkOrderNumberId(row.id)
    setSlideUpVisible(true)
    setWorkOrderNumber(row.workOrderNumber)
  }

  const handleDeleteClick = (row) => {
    setCurrentWorkOrderNumberId(row.id)
    setSlideUpVisibleForDelete(true)
  }

  const handleDelete = () => {
    if (currentWorkOrderNumberId) {
      dispatch(deleteWorkOrderNumber(jwtToken, currentWorkOrderNumberId))
      setSlideUpVisibleForDelete(false)
    }
  }

  const tableColumns = [
    {
      dataField: 'index',
      text: '#',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    // {
    //   dataField: 'id',
    //   text: 'ID',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   //   sort: true,
    // },
    // {
    //   dataField: 'worksite.storeName',
    //   text: 'Store Name',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    // },
    {
      dataField: 'corporate.corporateName',
      text: 'Corporate',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    {
      dataField: 'workOrderNumber',
      text: 'Work Order No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell, row) => {
        return (
          <Link to={`/admin/work-order/${row.worksiteId}/${row.workOrderNumber}`}>
            {row.workOrderNumber}
          </Link>
        );
      },
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return Number(a) - Number(b);
        }
        return Number(b) - Number(a);
      },
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      formatter: (cell) => {
        if (cell) {
          return new Date(cell).toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
          });
        } else {
          return '';
        }
      },
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();

        if (order === 'asc') {
          return dateA - dateB; // Sort in ascending order
        } else {
          return dateB - dateA; // Sort in descending order
        }
      }
    },
    // {
    //   dataField: 'updatedAt',
    //   text: 'Updated At',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    // },
    {
      text: 'Actions',
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvExport: false,
      formatter: (cell, row) => (
        <div>
          <Button type="primary" utilclass="m-r-5" iconposition="center" onClick={() => handleEditClick(row)}>
            <i className="pg-icon">edit</i>
            {/* <span className="">Edit</span> */}
          </Button>
          <Button type="danger" iconposition="center" onClick={() => handleDeleteClick(row)}>
            <i className="pg-icon">trash</i>
            {/* Delete */}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="page-container">
      <div className="page-content-wrapper ">
        <div className="content ">
          {/* <div className="jumbotron" data-pages="parallax"></div> */}

          <PageContainer className="bg-white container">
            <div className="card card-transparent">
              <div className="card-header  d-flex justify-content-between mb-3 titWbtn">
                <h3 class="text-primary no-margin text-uppercase">work orders</h3>
                <div className="export-options-container">
                  <div className="exportOptions">
                    <div className="DTTT d-flex align-items-center">
                      {/* <button aria-label="" className="btn btn-primary btn-lg m-r-10 " type="submit" onClick={handleAddClick}>
                        + Add Work Order Number
                      </button> */}
                      {csvProps && (
                        <ExportCSVButton {...csvProps}><i className="pg-icon m-r-10"><FiShare size={'15'} /></i> Export Work Order CSV</ExportCSVButton>
                      )}

                      {slideUpVisibleForDelete ?
                        <SlideUpModal visible={slideUpVisibleForDelete} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForDelete(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-top">
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForDelete(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                                <h5>Are you sure you want to delete?</h5>
                              </div>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-secondary btn-block" onClick={() => setSlideUpVisibleForDelete(false)}>
                                      Cancel
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-danger btn-block" onClick={handleDelete}>
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}

                      {slideUpVisible ?
                        <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5>{isEditMode ? 'Edit Work Order Number' : 'Add Work Order Number'}</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body p-5">
                                <form role="form">
                                  <div class="input-group d-flex flex-column">
                                    {!isEditMode && <>
                                      <label htmlFor='worksiteId'>Worksite<span style={{ color: 'red' }}>*</span></label>
                                      <GroupSelect
                                        inputId='worksiteId'
                                        value={worksiteId}
                                        options={worksiteOptions}
                                        onChange={(selectedOption) => handleInputChange({ name: 'worksiteId' }, selectedOption.value)}
                                      />
                                    </>}

                                    <input type="text" className="form-control w-100" placeholder='Enter Work Order Number' value={workOrderNumber} onChange={handleInputChange} />

                                  </div>

                                </form>
                              </div>
                              <div className='modal-footer'>
                                <button aria-label="" type="button" className="btn btn-primary" onClick={handleAddOrEdit}>
                                  {isEditMode ? 'Update' : '+ Add'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex'>
                <div className="input-group  w-auto col-md-3 p-l-0">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by work order number" value={searchValue} onChange={handleSearchChange} />
                </div>

                <div className="input-group w-auto col-md-3 p-l-0">
                  <GroupSelect
                    inputId='worksiteId'
                    value={worksiteId}
                    options={worksiteOptionsForFilter}
                    onChange={(selectedOption) => setWorksiteId(selectedOption?.value)}
                  />
                </div>
              </div>
              <div className="card-body custable">
                {!isLoading ? workOrderNumberWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={exportWorkOrdersData}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'workOrder-data.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      } return (
                        <React.Fragment>
                          <BootstrapTable
                            {...props.baseProps}
                            hover={true}
                            bootstrap4
                            bordered={false}
                            condensed={true}
                            striped={true}
                            pagination={paginationFactory(options)}
                          // cellEdit={cellEditFactory({
                          //   mode: 'click',
                          //   blurToSave: true,
                          // })}
                          />
                        </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                ) : <Loader />}
              </div>
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default WorkOrder;
