import React, { useState, useEffect } from 'react';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PageContainer from '../UIElements/Containers';
import SlideUpModal from '../Modals/SlideUpModal';

import {
  deletePhoneNumber,
  editPhoneNumber,
  getAllPhoneNumber,
  postPhoneNumber,
  resetPhoneNumberErrorData,
  resetPostPhoneNumberData,
  resetPostPhoneNumberErrorData,
  resetEditPhoneNumberData,
  resetEditPhoneNumberErrorData,
  resetDeletePhoneNumberData,
  resetDeletePhoneNumberErrorData,
} from '../../api-actions/adminDataActions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '../UIElements/Button/UtilsButton';
import { useLocation } from 'react-router-dom';
import { useRef } from 'react';
import GroupSelect from "../Forms/Elements/GroupSelect";
import { getAllWorkSites, resetGetCallRecordsByWorkOrderNumberData, resetWorkSitesErrorData } from '../../api-actions/workSiteDataAction';
import { FiShare } from 'react-icons/fi';
import NoData from '../NoData/NoData';
import Loader from '../Loader/Loader';

const PhoneNumber = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const dispatch = useDispatch();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const location = useLocation();

  const phoneNumberData = useSelector((state) => state.AdminData.phoneNumberData);
  const phoneNumberErrorData = useSelector((state) => state.AdminData.getPhoneNumberError);
  const postPhoneNumberData = useSelector((state) => state.AdminData.postPhoneNumberData);
  const postPhoneNumberError = useSelector((state) => state.AdminData.postPhoneNumberError);
  const editPhoneNumberData = useSelector((state) => state.AdminData.editPhoneNumberData);
  const editPhoneNumberError = useSelector((state) => state.AdminData.editPhoneNumberError);
  const deletePhoneNumberData = useSelector((state) => state.AdminData.deletePhoneNumberData);
  const deletePhoneNumberError = useSelector((state) => state.AdminData.deletePhoneNumberError);

  const workSitesData = useSelector((state) => state.WorkSiteData.workSitesData);
  const workSitesErrorData = useSelector((state) => state.WorkSiteData.getWorkSiteRecordsError);

  const [phoneNumberWithIndex, setPhoneNumberWithIndex] = useState([])
  const [phoneNumber, setPhoneNumber] = useState('');
  const [worksiteId, setWorksiteId] = useState('');
  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentPhoneNumberId, setCurrentPhoneNumberId] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [csvProps, setCsvProps] = useState(null);
  const [worksiteOptions, setWorksiteOptions] = useState([]);
  const [worksiteOptionsForFilter, setWorksiteOptionsForFilter] = useState([]);
  const csvPropsRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true)
  const [exportPhoneNumberData, setExportPhoneNumberData] = useState()

  const [filters, setFilters] = useState({
    storeNo: '',
    PhoneNo: '',
  });


  useEffect(() => {
    dispatch(getAllPhoneNumber(jwtToken));
    dispatch(getAllWorkSites(jwtToken));
  }, [])

  // useEffect(() => {
  //   if (csvPropsRef.current) {
  //     setCsvProps(csvPropsRef.current);
  //   }
  // }, [csvPropsRef.current]);

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="custom-dropdown-container">
      <select
        value={currSizePerPage}
        onChange={(e) => onSizePerPageChange(Number(e.target.value))}
        className="custom-form-control"
      >
        {options.map(option => (
          <option key={option.text} value={option.page}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
  
  const options = {
    sizePerPageRenderer
  }; 

  useEffect(() => {
    const { phoneNo, storeNo } = filters; // Extract filters from state

    let filteredData = phoneNumberData;

    // Pathname based filtering
    if (location?.pathname === "/admin/phone-number-unverified") {
      filteredData = filteredData.filter((item) => Number(item?.verified) === 0);
    } else if (location?.pathname === "/admin/phone-number-verified") {
      filteredData = filteredData.filter((item) => Number(item?.verified) === 1);
    } else if (location?.pathname === "/admin/phone-number") {
      // No additional filter, just use all data
      filteredData = phoneNumberData;
    }

    // Apply worksiteId filter if provided
    if (worksiteId?.length > 0) {
      filteredData = filteredData.filter(data => data.worksiteId === worksiteId);
    }

    // Apply phoneNo filter if provided
    if (phoneNo) {
      filteredData = filteredData.filter(data =>
        data.phoneNumber?.toLowerCase().includes(phoneNo.toLowerCase())
      );
    }

    // Apply storeNo filter if provided
    if (storeNo) {
      filteredData = filteredData.filter(data =>
        data.storeNumber?.toLowerCase().includes(storeNo.toLowerCase())
      );
    }

    setTimeout(()=>{
      setIsLoading(false);
    }, 1000);

    // Add index and update state
    setPhoneNumberWithIndex(filteredData.map((record, i) => ({ ...record, index: i + 1 })));
  }, [filters, location.pathname, phoneNumberData, worksiteId]);


  useEffect(() => {
    if (phoneNumberErrorData && Object.keys(phoneNumberErrorData)?.length > 0) {
      dispatch(resetPhoneNumberErrorData());
    }
    setTimeout(()=>{
      setIsLoading(false)
    },1000);
  }, [phoneNumberErrorData]);

  useEffect(() => {
    if (postPhoneNumberData && Object.keys(postPhoneNumberData)?.length > 0) {
      setPhoneNumber('');
      setIsPhoneNumberVerified(false);
      toast.success('Phone number added successfully');
      setSlideUpVisible(false);
      dispatch(getAllPhoneNumber(jwtToken));
      dispatch(resetPostPhoneNumberData());
    }
  }, [postPhoneNumberData]);

  useEffect(() => {
    if (postPhoneNumberError && Object.keys(postPhoneNumberError)?.length > 0) {
      toast.error(postPhoneNumberError.message);
      dispatch(resetPostPhoneNumberErrorData());
    }
  }, [postPhoneNumberError]);

  useEffect(() => {
    if (editPhoneNumberData && Object.keys(editPhoneNumberData)?.length > 0) {
      toast.success('Phone number updated successfully');
      setSlideUpVisible(false);
      dispatch(getAllPhoneNumber(jwtToken));
      dispatch(resetEditPhoneNumberData());
    }
  }, [editPhoneNumberData]);

  useEffect(() => {
    if (editPhoneNumberError && Object.keys(editPhoneNumberError)?.length > 0) {
      toast.error(editPhoneNumberError.message);
      dispatch(resetEditPhoneNumberErrorData());
    }
  }, [editPhoneNumberError]);

  useEffect(() => {
    if (deletePhoneNumberData && Object.keys(deletePhoneNumberData)?.length > 0) {
      toast.success('Phone number deleted successfully');
      dispatch(getAllPhoneNumber(jwtToken));
      dispatch(resetDeletePhoneNumberData());
    }
  }, [deletePhoneNumberData]);

  useEffect(() => {
    if (deletePhoneNumberError && Object.keys(deletePhoneNumberError)?.length > 0) {
      toast.error(deletePhoneNumberError.message);
      dispatch(resetDeletePhoneNumberErrorData());
    }
  }, [deletePhoneNumberError]);

  useEffect(() => {
    if (workSitesData) {
      setWorksiteOptions([]);
      setWorksiteOptionsForFilter([]);
      const worksiteData = workSitesData?.map((record) => ({
        value: record?.id,
        label: record?.storeName,
      }));

      setWorksiteOptions(Array.from(new Map(worksiteData.map(item => [item.value, item])).values()))

      const uniqueArray = Array.from(new Map(worksiteData.map(item => [item.value, item])).values());
      if (uniqueArray?.length > 0) {
        uniqueArray.unshift({ value: '', label: 'All Stores' });
      }
      setWorksiteOptionsForFilter(uniqueArray);
    }

  }, [workSitesData]);

  useEffect(() => {
    if (workSitesErrorData && Object.keys(workSitesErrorData)?.length > 0) {
      toast.error(workSitesErrorData.message);
      dispatch(resetWorkSitesErrorData());
    }
  }, [workSitesErrorData]);

  // Slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false]);
  const [slideUpWidth, setSlideUpWidth] = useState('500');
  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth('600');
    if (slideUpModalSize[1]) setSlideUpWidth('500');
    if (slideUpModalSize[2]) setSlideUpWidth('300');
  }, [slideUpModalSize]);

  const handleSearchChange = (value, type) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [type]: value
    }));
  };
  
  const handleInputChange = (e, selectOption = null) => {
    if (selectOption) {
      setWorksiteId(selectOption);
    } else {
      setPhoneNumber(e.target.value);
    }
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.checked === true ? 1 : 0;
    setIsPhoneNumberVerified(value);
  }

  const handleAddOrEdit = () => {
    const phoneNumberRegex = /^\+?\d+$/;
    const formattedPhoneNumber = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`
    if (formattedPhoneNumber === '' || !phoneNumberRegex.test(formattedPhoneNumber)) {
      return toast.error('Please enter a valid phone number');
    }
    if (isEditMode) {
      dispatch(editPhoneNumber(jwtToken, currentPhoneNumberId, { phoneNumber: formattedPhoneNumber, verified: isPhoneNumberVerified }));
    } else {
      dispatch(postPhoneNumber(jwtToken, { worksiteId, phoneNumber: formattedPhoneNumber, verified: isPhoneNumberVerified }));
    }
  };

  const handleAddClick = () => {
    setIsEditMode(false);
    setPhoneNumber('');
    setIsPhoneNumberVerified(false);
    setSlideUpVisible(true);
  }

  const handleEditClick = (row) => {
    setPhoneNumber(row.phoneNumber);
    setIsPhoneNumberVerified(row.verified)
    setIsEditMode(true);
    setCurrentPhoneNumberId(row.id);
    setSlideUpVisible(true);
  };

  const handleDeleteClick = (row) => {
    setCurrentPhoneNumberId(row.id);
    setSlideUpVisibleForDelete(true);
  };

  const handleDelete = () => {
    if (currentPhoneNumberId) {
      dispatch(deletePhoneNumber(jwtToken, currentPhoneNumberId));
      setSlideUpVisibleForDelete(false);
    }
  };

  useEffect(()=>{
    setExportPhoneNumberData(nullToEmpty(phoneNumberWithIndex))
  },[phoneNumberWithIndex]);

  const nullToEmpty = (data) => {
    return data?.map((item, i) => {
      let updatedItem = {};

      for (let key in item) {
        updatedItem[key] = item[key] === null ? "" : item[key];
        if ((key === 'createdAt') && updatedItem[key]) {
          updatedItem[key] = formatDate(updatedItem[key]);
        }
      }

      return {
        ...updatedItem,
        index: i + 1
      };
    });
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // 12-hour time format
    };
  
    // Format the date
    const formattedDate = date.toLocaleString('en-US', options);
    
    return formattedDate;
  };


  const tableColumns = [
    {
      dataField: 'index',
      text: '#',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    // {
    //   dataField: 'id',
    //   text: 'ID',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    // },
    {
      dataField: 'corporate.corporateName',
      text: 'Corporate',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      csvFormatter: (cell, row) => {
        return row.corporate && row.corporate.corporateName ? row.corporate.corporateName : '';
      }
    },
    {
      dataField: 'phoneNumber',
      text: 'Phone No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    // {
    //   dataField: 'verified',
    //   text: 'Verified',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    //   formatter: (cell, row) => (
    //     <div>
    //       {cell === 1 ? (
    //         <div className="btn btn-outline-success rounded-circle cusicon">
    //           <i className="pg-icon">tick</i>
    //         </div>
    //       ) : (
    //         <div className="btn btn-outline-danger rounded-circle cusicon">
    //           <i className="pg-icon">close</i>
    //         </div>
    //       )}
    //     </div>
    //   ),
    // },
    {
      dataField: 'verified',
      text: 'Verified',
      sort: true,
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell, row) => {
        // Apply custom rendering logic for the table
        return (
          <div>
            {cell === 1 ? (
              <div className="btn btn-outline-success rounded-circle cusicon">
                <i className="pg-icon">tick</i>
              </div>
            ) : (
              <div className="btn btn-outline-danger rounded-circle cusicon">
                <i className="pg-icon">close</i>
              </div>
            )}
          </div>
        );
      },
      csvExport: true,  // Enable export for this field
      csvFormatter: (cell, row) => {
        // Use plain text for export (no custom UI)
        return cell === 1 ? 'Yes' : 'No';
      },
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      formatter: (cell) => {
        if (cell) {
          return new Date(cell).toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
          });
        } else {
          return '';
        }
      },
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();
    
        // Handle invalid date scenarios
        if (isNaN(dateA) && isNaN(dateB)) return 0; // Both are invalid
        if (isNaN(dateA)) return order === 'asc' ? 1 : -1; // Only a is invalid
        if (isNaN(dateB)) return order === 'asc' ? -1 : 1; // Only b is invalid
    
        // Perform sorting for valid dates
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    // {
    //   dataField: 'updatedAt',
    //   text: 'Updated At',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    // },
    {
      text: 'Actions',
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvExport: false,
      formatter: (cell, row) => (
        <div>
          <Button type="primary" utilclass="m-r-5" iconposition="center" onClick={() => handleEditClick(row)}>
            <i className="pg-icon">edit</i>
            {/* <span className="">Edit</span> */}
          </Button>
          <Button type="danger" iconposition="center" onClick={() => handleDeleteClick(row)}>
            <i className="pg-icon">trash</i>
            {/* Delete */}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="page-container">
      <div className="page-content-wrapper">
        <div className="content">
          {/* <div className="jumbotron" data-pages="parallax"></div> */}

          <PageContainer className="bg-white container">
            <div className="card card-transparent">
              <div className="card-header  d-flex justify-content-between mb-3 titWbtn">
                <h3 class="text-primary no-margin text-uppercase">{location?.pathname === "/admin/phone-number-verified" ? "Verified Phone Numbers" : (location?.pathname === "/admin/phone-number-unverified") ? "Unverified Phone Numbers" : "All Phone Numbers"}</h3>
                <div className="export-options-container">
                  <div className="exportOptions">
                    <div className="DTTT d-flex align-items-center">
                      {/* <button aria-label="" className="btn btn-primary m-r-10 " type="button" onClick={handleAddClick}>
                        + Add Phone Number
                      </button> */}
                      {csvProps && (
                        <ExportCSVButton {...csvProps}><i className="pg-icon m-r-10"><FiShare size={'15'} /></i> Export Phone Number CSV</ExportCSVButton>
                      )}

                      {slideUpVisibleForDelete ?
                        <SlideUpModal visible={slideUpVisibleForDelete} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForDelete(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-top">
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForDelete(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                                <h5>Are you sure you want to delete?</h5>
                              </div>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-secondary btn-block" onClick={() => setSlideUpVisibleForDelete(false)}>
                                      Cancel
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-danger btn-block" onClick={handleDelete}>
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}

                      {slideUpVisible ?
                        <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">

                                <h5>{isEditMode ? 'Edit Phone Number' : 'Add Phone Number'}</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body p-5">
                                <form role="form">
                                  <div class="input-group flex-column">
                                    {!isEditMode && <>
                                      {/* <label htmlFor='worksiteId'>Worksite<span style={{ color: 'red' }}>*</span></label> */}
                                      <GroupSelect
                                        inputId='worksiteId'
                                        value={worksiteId}
                                        options={worksiteOptions}
                                        onChange={(selectedOption) => handleInputChange({ name: 'worksiteId' }, selectedOption.value)}
                                      />
                                    </>}

                                    <div className='d-flex mt-3'>
                                      <input type="text" className="form-control w-100" value={phoneNumber} onChange={handleInputChange} placeholder="Enter phone number" />
                                      <span class={isPhoneNumberVerified ? "input-group-text bg-success text-white" : "input-group-text  bg-secondary"} id="basic-addon2">
                                        <label className="form-check-label mt-1 d-flex align-items-center" htmlFor="verifiedCheckbox">
                                          <input type="checkbox" className="form-check-input mr-2 mt-0" id="verifiedCheckbox" checked={isPhoneNumberVerified} onChange={handleCheckboxChange} />
                                          {isPhoneNumberVerified ? "Verified" : 'Verify'}
                                        </label>
                                      </span>
                                    </div>
                                  </div>
                                </form>
                              </div>

                              <div className='modal-footer'>
                                <button aria-label="" type="button" className="btn btn-primary py-2 px-3" onClick={handleAddOrEdit}>
                                  {isEditMode ? 'Update' : '+ Add'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-row justify-content-start' >
                <div style={{ width: "200px" }} className='col-md-3 p-l-0' >
                  <GroupSelect
                    inputId='worksiteId'
                    value={worksiteId}
                    options={worksiteOptionsForFilter}
                    onChange={(selectedOption) => setWorksiteId(selectedOption?.value)}
                  />
                </div>
                <div className="input-group m-r-15 w-auto col-md-3">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by phone number"
                    value={filters.phoneNo} onChange={(e) => handleSearchChange(e.target.value, 'phoneNo')}
                  />
                </div>
                {/* <div className="input-group m-r-15 w-auto col-md-3">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search By Store Number"
                   value={filters.storeNo} onChange={(e) => handleSearchChange(e.target.value, 'storeNo')}
                  />
                </div> */}
              </div>

              <div className="card-body custable">
                {!isLoading ? phoneNumberWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={exportPhoneNumberData}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'phoneNumber-data.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      }
                      return (
                        <React.Fragment>
                          <BootstrapTable
                            {...props.baseProps}
                            hover={true}
                            bootstrap4
                            bordered={false}
                            condensed={true}
                            striped={true}
                            pagination={paginationFactory(options)}
                          />
                        </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                ): <Loader />}
              </div>
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;
