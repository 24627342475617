const CorporateDataActions = {
  GET_ALL_WORK_SITES_DATA: 'GET_ALL_WORK_SITES_DATA',
  GET_ALL_WORK_SITES_ERROR: 'GET_ALL_WORK_SITES_ERROR',

  GET_WORK_SITE_WORK_ORDER_NUMBERS_DATA: 'GET_WORK_SITE_WORK_ORDER_NUMBERS_DATA',
  GET_WORK_SITE_WORK_ORDER_NUMBERS_ERROR: 'GET_WORK_SITE_WORK_ORDER_NUMBERS_ERROR',

  GET_WORK_SITE_CALL_RECORDS_DATA: 'GET_WORK_SITE_CALL_RECORDS_DATA',
  GET_WORK_SITE_CALL_RECORDS_ERROR: 'GET_WORK_SITE_CALL_RECORDS_ERROR',

  GET_WORK_SITE_MANAGER_DATA: 'GET_WORK_SITE_MANAGER_DATA',
  GET_WORK_SITE_MANAGER_ERROR: 'GET_WORK_SITE_MANAGER_ERROR',

  GET_WORK_SITE_PHONE_NUMBERS_DATA: 'GET_WORK_SITE_PHONE_NUMBERS_DATA',
  GET_WORK_SITE_PHONE_NUMBERS_ERROR: 'GET_WORK_SITE_PHONE_NUMBERS_ERROR',

  GET_WORK_SITE_CHECKIN_RECORDS: 'GET_WORK_SITE_CHECKIN_RECORDS',
  GET_WORK_SITE_CHECKIN_RECORDS_ERROR: 'GET_WORK_SITE_CHECKIN_RECORDS_ERROR',

  GET_WORKSITE_TOLL_FREE_NUMBER: 'GET_WORKSITE_TOLL_FREE_NUMBER',
  GET_WORKSITE_TOLL_FREE_NUMBER_ERROR: 'GET_WORKSITE_TOLL_FREE_NUMBER_ERROR',

  GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_DATA: 'GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_DATA',
  GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_ERROR: 'GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_ERROR',

  GET_WORK_SITE_COMPLETED_VISITS_DATA: 'GET_WORK_SITE_COMPLETED_VISITS_DATA',
  GET_WORK_SITE_COMPLETED_VISITS_ERROR: 'GET_WORK_SITE_COMPLETED_VISITS_ERROR',

  POST_WORK_SITE_DATA: 'POST_WORK_SITE_DATA',
  POST_WORK_SITE_ERROR: 'POST_WORK_SITE_ERROR',

  POST_CALL_RECORD_DATA: 'POST_CALL_RECORD_DATA',
  POST_CALL_RECORD_ERROR: 'POST_CALL_RECORD_ERROR',
  POST_PHONE_NUMBER_DATA: 'POST_PHONE_NUMBER_DATA',
  POST_PHONE_NUMBER_ERROR: 'POST_PHONE_NUMBER_ERROR',
  POST_WORK_ORDER_NUMBER_DATA: 'POST_WORK_ORDER_NUMBER_DATA',
  POST_WORK_ORDER_NUMBER_ERROR: 'POST_WORK_ORDER_NUMBER_ERROR',
  POST_WORK_SITE_WORK_ORDER_NUMBERS_USING_CSV_DATA: 'POST_WORK_SITE_WORK_ORDER_NUMBERS_USING_CSV_DATA',
  POST_WORK_SITE_WORK_ORDER_NUMBERS_USING_CSV_ERROR: 'POST_WORK_SITE_WORK_ORDER_NUMBERS_USING_CSV_ERROR',
  EDIT_WORK_SITE_PASSWORD_DATA: 'EDIT_WORK_SITE_PASSWORD_DATA',
  EDIT_WORK_SITE_PASSWORD_ERROR: 'EDIT_WORK_SITE_PASSWORD_ERROR',

  EDIT_WORK_SITE_DATA: 'EDIT_WORK_SITE_DATA',
  EDIT_WORK_SITE_ERROR: 'EDIT_WORK_SITE_ERROR',
  EDIT_CALL_RECORD_DATA: 'EDIT_CALL_RECORD_DATA',
  EDIT_CALL_RECORD_ERROR: 'EDIT_CALL_RECORD_ERROR',
  EDIT_PHONE_NUMBER_DATA: 'EDIT_PHONE_NUMBER_DATA',
  EDIT_PHONE_NUMBER_ERROR: 'EDIT_PHONE_NUMBER_ERROR',
  EDIT_WORK_ORDER_NUMBER_DATA: 'EDIT_WORK_ORDER_NUMBER_DATA',
  EDIT_WORK_ORDER_NUMBER_ERROR: 'EDIT_WORK_ORDER_NUMBER_ERROR',

  UPDATE_CALL_RECORD_DATA: 'UPDATE_CALL_RECORD_DATA',
  UPDATE_CALL_RECORD_ERROR: 'UPDATE_CALL_RECORD_ERROR',

  DELETE_WORK_SITE_DATA: 'DELETE_WORK_SITE_DATA',
  DELETE_WORK_SITE_ERROR: 'DELETE_WORK_SITE_ERROR',
  DELETE_CALL_RECORD_DATA: 'DELETE_CALL_RECORD_DATA',
  DELETE_CALL_RECORD_ERROR: 'DELETE_CALL_RECORD_ERROR',
  DELETE_PHONE_NUMBER_DATA: 'DELETE_PHONE_NUMBER_DATA',
  DELETE_PHONE_NUMBER_ERROR: 'DELETE_PHONE_NUMBER_ERROR',
  DELETE_WORK_ORDER_NUMBER_DATA: 'DELETE_WORK_ORDER_NUMBER_DATA',
  DELETE_WORK_ORDER_NUMBER_ERROR: 'DELETE_WORK_ORDER_NUMBER_ERROR',
};

export default CorporateDataActions;
