import React, { useState, useEffect, useRef } from 'react';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import PageContainer from '../UIElements/Containers';

import { getAllWorkSites, postWorkSiteByCorporate, resetWorkSitesErrorData, resetPostWorkSiteData, resetWorkSiteErrorData } from '../../api-actions/workSiteDataAction';
import { useDispatch, useSelector } from 'react-redux';
import SlideUpModal from '../Modals/SlideUpModal';
import { toast } from 'react-toastify';
import Button from '../UIElements/Button/UtilsButton';
import GroupSelect from "../Forms/Elements/GroupSelect";
import moment from "moment";
import { RiShareBoxFill } from 'react-icons/ri';
import {
  getWorkSitesForCorporate,
  postWorksiteUsingCsv,
  editWorkSite,
  deleteWorkSite,
  resetDeleteWorkSiteData,
  resetDeleteWorkSiteErrorData,
  resetEditWorkSiteData,
  resetEditWorkSitesErrorData,
  resetPostWorksiteUsingCsvData,
  resetPostWorksiteUsingCsvError,
} from '../../api-actions/corporateDataAction';
import { useCSVReader, lightenDarkenColor, formatFileSize } from 'react-papaparse';
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FiDownload, FiShare } from 'react-icons/fi';
import Loader from '../Loader/Loader';
import NoData from '../NoData/NoData';

const WorkSite = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const { CSVReader } = useCSVReader();

  // CSV Reader Props
  const GREY = '#007BE8';
  const GREY_LIGHT = '#007BE8';
  const DEFAULT_REMOVE_HOVER_COLOR = '#EA2C54';
  const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40);
  const GREY_DIM = '#007BE8';

  const workSiteEmptyState = { storeNumber: '', storeName: '', address: '', address2: '', city: '', state: '', zip: '', country: '', timeZone: '', managerName: '', managerEmail: '', worksitePin: '', worksitePinid: '' };
  const countriesList = [
    { label: 'Canada', value: 'Canada' },
    { label: 'United States', value: 'United States' },
    { label: 'Mexico', value: 'Mexico' },
    { label: 'Guatemala', value: 'Guatemala' },
    { label: 'Belize', value: 'Belize' },
    { label: 'El Salvador', value: 'El Salvador' },
    { label: 'Honduras', value: 'Honduras' },
    { label: 'Nicaragua', value: 'Nicaragua' },
    { label: 'Costa Rica', value: 'Costa Rica' },
    { label: 'Panama', value: 'Panama' },
    { label: 'Cuba', value: 'Cuba' },
    { label: 'Jamaica', value: 'Jamaica' },
    { label: 'Haiti', value: 'Haiti' },
    { label: 'Dominican Republic', value: 'Dominican Republic' },
    { label: 'Bahamas', value: 'Bahamas' },
    { label: 'Barbados', value: 'Barbados' },
    { label: 'Saint Lucia', value: 'Saint Lucia' },
    { label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines' },
    { label: 'Grenada', value: 'Grenada' },
    { label: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
  ]

  const timeZoneList = [
    { label: 'Eastern Time (US & Canada) (UTC-05:00)', value: 'America/New_York' },
    { label: 'Eastern Time (Detroit) (UTC-05:00)', value: 'America/Detroit' },
    { label: 'Eastern Time (Toronto) (UTC-05:00)', value: 'America/Toronto' },
    { label: 'Central Time (US & Canada) (UTC-06:00)', value: 'America/Chicago' },
    { label: 'Central Time (Guadalajara) (UTC-06:00)', value: 'America/Guadalajara' },
    { label: 'Central Time (Mexico City) (UTC-06:00)', value: 'America/Mexico_City' },
    { label: 'Mountain Time (US & Canada) (UTC-07:00)', value: 'America/Denver' },
    { label: 'Mountain Time (Edmonton) (UTC-07:00)', value: 'America/Edmonton' },
    { label: 'Pacific Time (US & Canada) (UTC-08:00)', value: 'America/Los_Angeles' },
    { label: 'Pacific Time (Vancouver) (UTC-08:00)', value: 'America/Vancouver' },
    { label: 'Atlantic Time (Halifax) (UTC-04:00)', value: 'America/Halifax' },
    { label: 'Uruguay Time (Montevideo) (UTC-03:00)', value: 'America/Montevideo' },
    { label: 'Colombia Time (Bogota) (UTC-05:00)', value: 'America/Bogota' },
    { label: 'Peru Time (Lima) (UTC-05:00)', value: 'America/Lima' }
  ];

  const dispatch = useDispatch();
  const userId = useSelector((state) => state.AdminAuthData.userId);
  const userType = useSelector((state) => state.AdminAuthData.userType);
  const postWorksitesUsingCsvData = useSelector((state) => state.CorporateData.postCorporateWorksitesData);
  const postWorksitesUsingCsvError = useSelector((state) => state.CorporateData.postCorporateWorksitesError);

  const workSitesData = useSelector((state) => state.WorkSiteData.workSitesData);
  const workSitesErrorData = useSelector((state) => state.WorkSiteData.getWorkSiteRecordsError);

  const postWorkSiteData = useSelector((state) => state.WorkSiteData.postWorkSiteData);
  const postWorkSiteError = useSelector((state) => state.WorkSiteData.postWorkSiteError);

  const editWorkSiteData = useSelector((state) => state.CorporateData.editWorkSiteData);
  const editWorkSiteError = useSelector((state) => state.CorporateData.editWorkSiteError);

  const deleteWorkSiteData = useSelector((state) => state.CorporateData.deleteWorkSiteData);
  const deleteWorkSiteRecordError = useSelector((state) => state.CorporateData.deleteWorkSiteRecordError);

  const workSitesForCorporateData = useSelector((state) => state.CorporateData.getWorkSitesForCorporateData);
  const workSitesForCorporateError = useSelector((state) => state.CorporateData.getWorkSitesForCorporateError);

  const [filters, setFilters] = useState({
    storeNo: '',
    storeName: '',
    city: '',
    state: '',
    zip: '',
    email: ''
  });

  const [workSiteWithIndex, setWorkSiteWithIndex] = useState([]);
  const [exportWorkSiteData, setExportWorkSiteData] = useState()
  const [workSite, setWorkSite] = useState(workSiteEmptyState)
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentWorkSiteId, setCurrentWorkSiteId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  //slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpVisibleForBulkImport, setSlideUpVisibleForBulkImport] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false]);
  const [slideUpWidth, setSlideUpWidth] = useState("500");

  // CSV
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);
  const [bulkImportFile, setBulkImportFile] = useState('');
  const [csvProps, setCsvProps] = useState(null);
  const csvPropsRef = useRef(null);

  const [slideUpVisibleForViewData, setSlideUpVisibleForViewData] = useState(false);
  const [viewData, setViewData] = useState(false);

  //DatePicker
  let endDate = new Date();
  endDate.setDate(endDate.getDate() + 30);
  const [startDateRange, setStartDateRange] = useState(new Date());
  const [endDateRange, setEndDateRange] = useState(endDate);

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="custom-dropdown-container">
      <select
        value={currSizePerPage}
        onChange={(e) => onSizePerPageChange(Number(e.target.value))}
        className="custom-form-control"
      >
        {options.map(option => (
          <option key={option.text} value={option.page}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
  
  const options = {
    sizePerPageRenderer
  };

  useEffect(() => {
    setIsLoading(true)
    if(userId) {
      dispatch(getWorkSitesForCorporate(jwtToken, userId));
    }
  }, [jwtToken, userType, userId]);

  useEffect(() => {
    const data = workSitesForCorporateData?.map((workSite, i) => (
      { ...workSite, index: i + 1 }
    ));
    setWorkSiteWithIndex(data);
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [workSitesForCorporateData]);

  useEffect(() => {
    if (workSitesErrorData && Object.keys(workSitesErrorData)?.length > 0) {
      dispatch(resetWorkSiteErrorData());
    }
  }, [workSitesErrorData]);

  useEffect(() => {
    if (workSitesForCorporateError && Object.keys(workSitesForCorporateError)?.length > 0) {
      dispatch(resetWorkSiteErrorData());
      setIsLoading(false)
    }
  }, [workSitesForCorporateError]);

  useEffect(() => {
    if (postWorkSiteData && Object.keys(postWorkSiteData)?.length > 0) {
      setWorkSite(workSiteEmptyState)
      toast.success('Work site added successfully')
      setSlideUpVisible(false)
      dispatch(getWorkSitesForCorporate(jwtToken, userId));
      dispatch(resetPostWorkSiteData())
    }
  }, [postWorkSiteData])

  useEffect(() => {
    if (postWorkSiteError && Object.keys(postWorkSiteError)?.length > 0) {
      toast.error(postWorkSiteError.message)
      dispatch(resetWorkSiteErrorData());
    }
  }, [postWorkSiteError]);

  useEffect(() => {
    if (editWorkSiteData && Object.keys(editWorkSiteData)?.length > 0) {
      setWorkSite(workSiteEmptyState);
      toast.success('Work site updated successfully');
      setSlideUpVisible(false);
      dispatch(getWorkSitesForCorporate(jwtToken, userId));
      dispatch(resetEditWorkSiteData());
    }
  }, [editWorkSiteData]);

  useEffect(() => {
    if (editWorkSiteError && Object.keys(editWorkSiteError)?.length > 0) {
      toast.error(editWorkSiteError.message);
      dispatch(resetEditWorkSitesErrorData());
    }
  }, [editWorkSiteError]);

  useEffect(() => {
    if (deleteWorkSiteData && Object.keys(deleteWorkSiteData)?.length > 0) {
      toast.success('Work site record deleted successfully');
      if (userType === 'admin') {
        dispatch(getAllWorkSites(jwtToken));
      } else if (userType === 'corporate' && userId) {
        dispatch(getWorkSitesForCorporate(jwtToken, userId));
      }
      dispatch(resetDeleteWorkSiteData());
    }
  }, [deleteWorkSiteData]);

  useEffect(() => {
    if (deleteWorkSiteRecordError && Object.keys(deleteWorkSiteRecordError)?.length > 0) {
      toast.error(deleteWorkSiteRecordError.message);
      dispatch(resetDeleteWorkSiteErrorData());
    }
  }, [deleteWorkSiteRecordError]);

  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth("600");
    if (slideUpModalSize[1]) setSlideUpWidth("700");
    if (slideUpModalSize[2]) setSlideUpWidth("300");
  }, [slideUpModalSize]);

  useEffect(() => {
    if (postWorksitesUsingCsvData && Object.keys(postWorksitesUsingCsvData)?.length > 0) {
      setBulkImportFile('')
      toast.success('Work sites added successfully')
      setSlideUpVisibleForBulkImport(false)
      dispatch(getWorkSitesForCorporate(jwtToken, userId));
      dispatch(resetPostWorksiteUsingCsvData())
    }
  }, [postWorksitesUsingCsvData])

  useEffect(() => {
    if (postWorksitesUsingCsvError && Object.keys(postWorksitesUsingCsvError)?.length > 0) {
      toast.error(postWorksitesUsingCsvError.message)
      dispatch(resetPostWorksiteUsingCsvError());
    }
  }, [postWorksitesUsingCsvError]);

  useEffect(()=>{
    setExportWorkSiteData(nullToEmpty(workSiteWithIndex));
  },[workSiteWithIndex]);

  const nullToEmpty = (data) => {
    return data?.map((item, i) => {
      let updatedItem = {};

      for (let key in item) {
        updatedItem[key] = item[key] === null ? "" : item[key];
        if ((key === 'createdAt') && updatedItem[key]) {
          updatedItem[key] = formatDate(updatedItem[key]);
        }
      }

      return {
        ...updatedItem,
        index: i + 1
      };
    });
  }

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // 12-hour time format
    };
  
    // Format the date
    const formattedDate = date.toLocaleString('en-US', options);
    
    return formattedDate;
  };


  const handleInputChange = (e, selectOption = null) => {
    if (selectOption) {
      setWorkSite((prevWorkSite) => ({
        ...prevWorkSite,
        [e.name]: selectOption.value
      }));
    } else {
      const { name, value } = e.target;
      setWorkSite((prevWorkSite) => ({
        ...prevWorkSite,
        [name]: value
      }));
    }
  };

  const handleAddOrEdit = () => {
    const missingFields = [];
    if(!workSite.managerName) missingFields.push('Manager Name');
    if(!workSite.managerEmail) missingFields.push('Manager Email');
    if(!isEditMode) {
      if(!workSite.managerPassword) missingFields.push('Manager Password');
    }
    if(!workSite.storeName) missingFields.push('Store Name');
    if(!workSite.storeNumber) missingFields.push('Store Number');
    if(!workSite.address) missingFields.push('Address');
    if(!workSite.city) missingFields.push('City');
    if(!workSite.state) missingFields.push('State');
    if(!workSite.zip) missingFields.push('Zip');
    if(!workSite.country) missingFields.push('Country');
    if(!workSite.timeZone) missingFields.push('TimeZone');

    if(missingFields.length > 0) {
      return toast.error('Please fill all the required fields')
    }

    if (isEditMode) {
      dispatch(editWorkSite(jwtToken, currentWorkSiteId, workSite));
    } else {
      dispatch(postWorkSiteByCorporate(jwtToken, { ...workSite, corporateId: userId }));
    }
  };

  const handleAddClick = () => {
    setIsEditMode(false);
    setWorkSite(workSiteEmptyState);
    setSlideUpVisible(true);
  }

  const formateDate = (newDate) => {
    const originalDate = new Date(newDate);
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      // timeZone: 'UTC'
    };

    const formattedDate = originalDate.toLocaleString('en-US', options)
      .replace(',', '');
    return formattedDate;
  }

  const handleEditClick = (row) => {
    setIsEditMode(true)
    setCurrentWorkSiteId(row.id)
    setSlideUpVisible(true)
    setWorkSite({
      storeNumber: row.storeNumber,
      storeName: row.storeName,
      address: row.address,
      address2: row.address2,
      city: row.city,
      state: row.state,
      zip: row.zip,
      country: row.country,
      timeZone: row.timeZone,
      managerName: row.managerName,
      managerEmail: row.managerEmail,
      worksitePin: row.worksitePin,
      worksitePinId: row.worksitePinId,
    })
  }

  const handleDeleteClick = (row) => {
    setCurrentWorkSiteId(row.id)
    setSlideUpVisibleForDelete(true)
  }

  const handleDelete = () => {
    if (currentWorkSiteId) {
      dispatch(deleteWorkSite(jwtToken, currentWorkSiteId))
      setSlideUpVisibleForDelete(false)
    }
  }

  const handleViewClick = (row) => {
    setViewData(row);
    setSlideUpVisibleForViewData(true);
  };

  const handleDownloadSampleFile = () => {
    const sampleFileUrl = '/assets/csv/work-site-file.csv';
    const a = document.createElement('a');
    a.href = sampleFileUrl;
    a.download = 'work-sites-file.csv';
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleBulkImportUpload = () => {
    dispatch(postWorksiteUsingCsv(jwtToken, { corporateId: userId, csvData: bulkImportFile.data }))
  }

  const handleBulkImport = () => {
    setSlideUpVisibleForBulkImport(true)
  }

  useEffect(() => {
    const filteredData = workSitesForCorporateData.filter(data => {
      const storeNoMatch = filters.storeNo === '' || data.storeNumber?.toLowerCase().includes(filters.storeNo.toLowerCase());
      const storeNameMatch = filters.storeName === '' || data.storeName?.toLowerCase().includes(filters.storeName.toLowerCase());
      const cityMatch = filters.city === '' || data.city?.toLowerCase().includes(filters.city.toLowerCase());
      const stateMatch = filters.state === '' || data.state?.toLowerCase().includes(filters.state.toLowerCase());
      const zipMatch = filters.zip === '' || data.zip?.toLowerCase().includes(filters.zip.toLowerCase());
      const emailMatch = filters.email === '' || data.managerEmail?.toLowerCase().includes(filters.email.toLowerCase());

      return (
        storeNoMatch &&
        storeNameMatch &&
        cityMatch &&
        stateMatch &&
        zipMatch &&
        emailMatch
      );
    }).map((record, i) => ({ ...record, index: i + 1 }));

    setWorkSiteWithIndex(filteredData);
  }, [filters, workSitesForCorporateData]);



  const handleSearchChange = (value, type) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [type]: value
    }));
  };



  // Callback to get CSV props from ToolkitProvider
  // useEffect(() => {
  //   if (csvPropsRef.current) {
  //     setCsvProps(csvPropsRef.current);
  //   }
  // }, [csvPropsRef.current]);


  // CSV Styles
  const styles = {
    zone: {
      alignItems: 'center',
      border: `2px dashed ${GREY}`,
      borderRadius: 20,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      padding: 20,
    },
    file: {
      background: 'linear-gradient(to bottom, #007BE8, #007BE8)',
      borderRadius: 20,
      display: 'flex',
      height: 100,
      width: 300,
      position: 'relative',
      zIndex: 10,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    info: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 10,
      paddingRight: 10,
    },
    size: {
      backgroundColor: GREY_LIGHT,
      borderRadius: 3,
      marginBottom: '0.5em',
      justifyContent: 'center',
      display: 'flex',
      color: 'fff',
    },
    name: {
      backgroundColor: GREY_LIGHT,
      borderRadius: 3,
      fontSize: 12,
      marginBottom: '0.5em',
      color: '#fff',
    },
    progressBar: {
      bottom: 14,
      position: 'absolute',
      width: '100%',
      paddingLeft: 10,
      paddingRight: 10,
      color: '#fff'
    },
    zoneHover: {
      borderColor: GREY_DIM,
    },
    default: {
      borderColor: GREY,
    },
    remove: {
      height: 23,
      position: 'absolute',
      right: 6,
      top: 6,
      width: 23,
    },
  };


  const tableColumns = [
    {
      dataField: 'index',
      text: '#',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    // {
    //   dataField: 'id',
    //   text: 'ID',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   //   sort: true,
    // },
    {
      dataField: 'storeNumber',
      text: 'Store Number',
      headerStyle: { backgroundColor: '#f0f0f073', width: '10%' },
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return Number(a) - Number(b);
        }
        return Number(b) - Number(a);
      },
    },
    {
      dataField: 'storeName',
      text: 'Store Name',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    {
      dataField: 'address',
      text: 'Address',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // sort: true,
    },
    {
      dataField: 'address2',
      text: 'Address 2',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // sort: true,
    },
    {
      dataField: 'city',
      text: 'City',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // sort: true,
    },
    {
      dataField: 'state',
      text: 'State',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // sort: true,
    },
    {
      dataField: 'zip',
      text: 'Zip',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // sort: true,
    },
    {
      dataField: 'country',
      text: 'Country',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // sort: true,
    },
    {
      dataField: 'timeZone',
      text: 'Time Zone',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // sort: true,
    },
    {
      dataField: 'managerName',
      text: 'Manager',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // sort: true,
    },
    {
      dataField: 'managerEmail',
      text: 'Manager Email',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // sort: true,
    },
    {
      dataField: 'worksitePin',
      text: 'Work Site Pin',
      headerStyle: { backgroundColor: '#f0f0f073' },
      // sort: true,
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      headerStyle: () => {
        return { width: '200px', backgroundColor: '#f0f0f073' }; // Fixed width
      },
      style: { width: '200px' },
      sort: true,
      // sortCaret: (order, column) => {
      //   if (!order) return <span>&nbsp;&uarr;&darr;</span>;
      //   else if (order === 'asc') return <span>&nbsp;&uarr;</span>;
      //   else if (order === 'desc') return <span>&nbsp;&darr;</span>;
      //   return null;
      // },
      formatter: (cell) => {
        if (cell) {
          return new Date(cell).toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit',
            // hour12: true,
          });
        } else {
          return '';
        }
      },
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();
      
        if (order === 'asc') {
          return dateA - dateB; // Sort in ascending order
        } else {
          return dateB - dateA; // Sort in descending order
        }
      }
    },
    // {
    //   dataField: 'updatedAt',
    //   text: 'Updated At',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    // },
    {
      text: 'Actions',
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvExport: false,
      formatter: (cell, row) => (
        <div>
          <Button
            style={{ backgroundColor: 'green', borderColor: 'green' }}
            type="primary"
            utilclass="m-r-5"
            iconposition="center"
            onClick={() => handleViewClick(row)}
          >
            <i className="pg-icon"><RiShareBoxFill color='white' /></i>
          </Button>
          <Button type="primary" utilclass="m-r-5" iconposition="center" onClick={() => handleEditClick(row)}>
            <i className="pg-icon">edit</i>
            {/* <span className="">Edit</span> */}
          </Button>
          <Button type="danger" iconposition="center" onClick={() => handleDeleteClick(row)}>
            <i className="pg-icon">trash</i>
            {/* Delete */}
          </Button>
        </div>
      ),
    },
  ];

  const handleDateRange = (event, picker) => {
    // const filteredData = callRecords.filter(data => {
    //   const createdAtDate = new Date(data.createdAt);
    //   return createdAtDate >= picker.startDate.toDate() && createdAtDate <= picker.endDate.toDate();
    // }).map((record, i) => ({ ...record, index: i + 1 }));

    // setCallRecordsWithIndex(filteredData);
    setStartDateRange(picker.startDate.toDate());
    setEndDateRange(picker.endDate.toDate());
  };

  return (
    <div className="page-container">
      <div className="page-content-wrapper ">
        <div className="content ">
          {/* <div className="jumbotron" data-pages="parallax"></div> */}

          <PageContainer className="bg-white container">
            <div className="card card-transparent">
              <div className="card-header  d-flex justify-content-between mb-3 titWbtn">
                <h3 class="text-primary no-margin text-uppercase">WorkSite</h3>
                <div className="export-options-container">
                  <div className="exportOptions">
                    <div className="DTTT">
                      {/* <div className="input-group">
                        <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                        <input type="text" className="form-control p-l-10" placeholder="Search" value={searchValue} onChange={handleSearchChange} />
                      </div> */}
                      <button aria-label="" className="btn btn-primary btn-lg m-r-10" type="submit" onClick={handleAddClick}>
                        + Add Worksite
                      </button>

                      <button aria-label="" className="btn btn-secondary btn-lg m-r-10" type="submit" onClick={handleBulkImport}>
                        <i className="pg-icon m-r-10"><FiDownload size={'15'} /></i> Bulk Import
                      </button>
                      {csvProps && (
                        <ExportCSVButton {...csvProps}><i className="pg-icon m-r-10"><FiShare size={'15'} /></i> Export Worksite CSV</ExportCSVButton>
                      )}

                      {slideUpVisibleForBulkImport ?
                        <SlideUpModal visible={slideUpVisibleForBulkImport} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForBulkImport(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5>Upload Work Sites</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForBulkImport(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>

                              <div className="modal-body p-5">

                                <CSVReader
                                  onUploadAccepted={(results) => {
                                    setBulkImportFile(results)
                                  }}
                                  onDragOver={(event) => {
                                    event.preventDefault();
                                    setZoneHover(true);
                                  }}
                                  onDragLeave={(event) => {
                                    event.preventDefault();
                                    setZoneHover(false);
                                  }}
                                >
                                  {({
                                    getRootProps,
                                    acceptedFile,
                                    ProgressBar,
                                    getRemoveFileProps,
                                    Remove,
                                  }) => (
                                    <>
                                      <div
                                        {...getRootProps()}
                                        style={Object.assign(
                                          {},
                                          styles.zone,
                                          zoneHover && styles.zoneHover
                                        )}
                                      >
                                        {acceptedFile ? (
                                          <>
                                            <div style={styles.file}>
                                              <div style={styles.info}>
                                                <span style={styles.size}>
                                                  {formatFileSize(acceptedFile.size)}
                                                </span>
                                                <span style={styles.name}>{acceptedFile.name}</span>
                                              </div>
                                              <div style={styles.progressBar}>
                                                <ProgressBar />
                                              </div>
                                              <div
                                                {...getRemoveFileProps()}
                                                style={styles.remove}
                                                onMouseOver={(event) => {
                                                  event.preventDefault();
                                                  setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                                                }}
                                                onMouseOut={(event) => {
                                                  event.preventDefault();
                                                  setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                                                }}
                                              >
                                                <Remove color={removeHoverColor} />
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          'Drop CSV file here or click to upload'
                                        )}
                                      </div>
                                    </>
                                  )}
                                </CSVReader>

                                <div className="row mt-4">
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-secondary btn-block" onClick={handleDownloadSampleFile}>
                                      Sample File
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button aria-label="" disabled={bulkImportFile === ''} type="button" className="btn btn-primary btn-block" onClick={handleBulkImportUpload}>
                                      Upload
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}

                      {slideUpVisibleForDelete ?
                        <SlideUpModal visible={slideUpVisibleForDelete} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForDelete(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-top">
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForDelete(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                                <h5>Are you sure you want to delete?</h5>
                              </div>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-secondary btn-block" onClick={() => setSlideUpVisibleForDelete(false)}>
                                      Cancel
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-danger btn-block" onClick={handleDelete}>
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}

                      {slideUpVisible ?
                        <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5>{isEditMode ? 'Edit Worksite Record' : 'Add Worksite Record'}</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body p-5">
                                <form role="form">
                                  <div class="input-group flex-column">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label for='storeNumber'>Store Number<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Store Number' : 'Enter Store Number'} value={workSite.storeNumber} onChange={handleInputChange} name='storeNumber' id='storeNumber' />
                                      </div>

                                      <div className="col-md-6">
                                        <label for='storeName'>Store Name<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100"
                                          placeholder={isEditMode ? 'Update Store Name' : 'Enter Store Name'} value={workSite.storeName} onChange={handleInputChange} name='storeName' id='storeName' />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6">
                                        <label for='address' className='mt-3'>Address<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Address' : 'Enter Address'} value={workSite.address} onChange={handleInputChange} name='address' id='address' />
                                      </div>
                                      <div className="col-md-6">
                                        <label for='address2' className='mt-3'>Address 2{isEditMode ? "" : <span style={{ color: 'red' }}>*</span>}</label>
                                        <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Address' : 'Enter Address'} value={workSite.address2} onChange={handleInputChange} name='address2' id='address2' />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6">
                                        <label for='city' className='mt-3'>City<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update City' : 'Enter City'} value={workSite.city} onChange={handleInputChange} name='city' id='city' />
                                      </div>


                                      <div className="col-md-6">
                                        <label for='state' className='mt-3'>State<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update State' : 'Enter State'} value={workSite.state} onChange={handleInputChange} name='state' id='state' />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-3">

                                        <label for='zip' className='mt-3'>Zip<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Zip' : 'Enter Zip'} value={workSite.zip} onChange={handleInputChange} name='zip' id='zip' />
                                      </div>

                                      <div className="col-md-9">
                                        <label for='country' className='mt-3'>Country<span style={{ color: 'red' }}>*</span></label>
                                        <GroupSelect
                                          inputId='country'
                                          value={workSite.country}
                                          options={countriesList}
                                          onChange={(selectedOption) => handleInputChange({ name: 'country' }, selectedOption)}
                                        />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6">
                                        <label for='timeZone' className='mt-3'>Time Zone<span style={{ color: 'red' }}>*</span></label>
                                        <GroupSelect
                                          inputId='timeZone'
                                          value={workSite.timeZone}
                                          options={timeZoneList}
                                          onChange={(selectedOption) => handleInputChange({ name: 'timeZone' }, selectedOption)}
                                        />
                                      </div>

                                      <div className="col-md-6">
                                        <label for='managerName' className='mt-3'>Manager Name<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Manager Name' : 'Enter Manager Name'} value={workSite.managerName} onChange={handleInputChange} name='managerName' id='managerName' />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6">
                                        <label for='managerEmail' className='mt-3'>Manager Email<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Manager Email' : 'Enter Manager Email'} value={workSite.managerEmail} onChange={handleInputChange} name='managerEmail' id='managerEmail' />
                                      </div>

                                      <div className="col-md-6">
                                        {isEditMode ? (
                                          <>
                                            <label for='worksitePin' className='mt-3'>Work Site Pin<span style={{ color: 'red' }}>*</span></label>
                                            <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Work Site Pin' : 'Enter Work Site Pin'} value={workSite.worksitePin} disabled name='worksitePin' id='worksitePin' />
                                          </>
                                        ) :
                                          <>
                                            <label htmlFor='managerPassword' className='mt-3'>Manager Password<span style={{ color: 'red' }}>*</span></label>
                                            <input type="password" className="form-control w-100" placeholder='Enter Manager Password' value={workSite.managerPassword} onChange={handleInputChange} name='managerPassword' id='managerPassword' />
                                          </>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>

                              <div className='modal-footer'>
                                <button aria-label="" type="button" className="btn btn-primary py-2 px-3" onClick={handleAddOrEdit}>
                                  {isEditMode ? 'Update' : '+ Add'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}

                      {slideUpVisibleForViewData ?
                        <SlideUpModal visible={slideUpVisibleForViewData} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForViewData(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5>Worksite Details</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForViewData(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body py-3 h100vh">
                                <div className="row">
                                  <div className="col-md-6">
                                    {/* <p><strong>ID:</strong> {viewData.id}</p> */}

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Manager Name</p>
                                          {viewData.managerName ?
                                            <p class="bold mb-0">{viewData.managerName}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Store Number</p>
                                          {viewData.storeNumber ?
                                            <p class="bold mb-0">{viewData.storeNumber}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Address</p>
                                          {viewData.address ?
                                            <p class="bold mb-0">{viewData.address}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>City</p>
                                          {viewData.city ?
                                            <p class="bold mb-0">{viewData.city}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Zip</p>
                                          {viewData.zip ?
                                            <p class="bold mb-0">{viewData.zip}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Country</p>
                                          {viewData.country ?
                                            <p class="bold mb-0">{viewData.country}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Created At</p>
                                          <p class="bold mb-0">{formateDate(viewData?.createdAt)}</p>
                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                  <div className="col-md-6">

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Manager Email</p>
                                          {viewData.managerEmail ?
                                            <p class="bold mb-0">{viewData.managerEmail}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Store Name</p>
                                          {viewData.storeName ?
                                            <p class="bold mb-0">{viewData.storeName}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Address 2</p>
                                          {viewData.address2 ?
                                            <p class="bold mb-0">{viewData.address2}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>State</p>
                                          {viewData.state ?
                                            <p class="bold mb-0">{viewData.state}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Worksite Pin</p>
                                          {viewData.worksitePin ?
                                            <p class="bold mb-0">{viewData.worksitePin}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Time Zone</p>
                                          {viewData.timeZone ?
                                            <p class="bold mb-0">{viewData.timeZone}</p> :
                                            <p class="bold mb-0 ml-1">{"-"}</p>
                                          }
                                        </div>
                                      </div>
                                    </div>

                                    <div className='social-card share w-100 mb-3'>
                                      <div class="pgn-message card-header">
                                        <div>
                                          <p>Updated At</p>
                                          <p class="bold mb-0">{formateDate(viewData?.updatedAt)}</p>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>


                              </div>
                            </div>
                          </div>
                        </SlideUpModal>
                        : null
                      }
                    </div>
                  </div>

                </div>
              </div>
              <div className="d-flex flex-row justify-content-start">
                <div className="input-group col-md-2 p-l-0">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by store no"
                    value={filters.storeNo} onChange={(e) => handleSearchChange(e.target.value, 'storeNo')}
                  />
                </div>
                <div className="input-group col-md-2">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by store name"
                    value={filters.storeName} onChange={(e) => handleSearchChange(e.target.value, 'storeName')}
                  />
                </div>
                <div className="input-group col-md-2">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by city"
                    value={filters.city} onChange={(e) => handleSearchChange(e.target.value, 'city')}
                  />
                </div>
                <div className="input-group col-md-2">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by state"
                    value={filters.state} onChange={(e) => handleSearchChange(e.target.value, 'state')}
                  />
                </div>
                <div className="input-group col-md-2">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by zip"
                    value={filters.zip} onChange={(e) => handleSearchChange(e.target.value, 'zip')}
                  />
                </div>
                <div className="input-group col-md-2">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by email"
                    value={filters.email} onChange={(e) => handleSearchChange(e.target.value, 'email')}
                  />
                </div>
                {/* <div>
                  <DateRangePicker
                    // startDate={startDateRange} endDate={endDateRange} 
                    showTimeSelect
                   onApply={handleDateRange}
                  >
                    <div className="input-group">
                      <span className="input-group-text input-date-icon-pre">
                        <i className="pg-icon">calendar</i>
                      </span>
                      <input type="text" name="reservation" id="daterangepicker" onChange={() => { }} className="form-control"
                        value={`${moment(startDateRange).format("MM/DD/YYYY h:mm A")} - ${moment(endDateRange).format("MM/DD/YYYY h:mm A")}`}
                      />
                    </div>
                  </DateRangePicker>
                </div> */}
              </div>
              {!isLoading ? <div className="card-body custable">
                {workSiteWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={exportWorkSiteData}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'workSite-data.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      }
                      return (
                        <React.Fragment>
                          <BootstrapTable
                            {...props.baseProps}
                            hover={true}
                            bootstrap4
                            bordered={false}
                            condensed={true}
                            striped={true}
                            pagination={paginationFactory(options)}
                          />
                        </React.Fragment>)
                    }
                    }
                  </ToolkitProvider>
                ) : (
                  <NoData />
                )}
              </div> : <Loader />}
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default WorkSite;
