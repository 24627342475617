import React, { useState, useEffect, useRef } from 'react';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PageContainer from '../UIElements/Containers';
import SlideUpModal from '../Modals/SlideUpModal';
import {
  deletePhoneNumberByWorksite,
  editPhoneNumberByWorksite,
  postPhoneNumberByWorksite,
  getWorksitePhoneNumbers,
  resetWorkSitePhoneNumbersErrorData,
  resetPostPhoneNumberData,
  resetPostPhoneNumberErrorData,
  resetEditPhoneNumberData,
  resetEditPhoneNumberErrorData,
  resetDeletePhoneNumberData,
  resetDeletePhoneNumberErrorData,
} from '../../api-actions/workSiteDataAction';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '../UIElements/Button/UtilsButton';
import { useLocation } from 'react-router-dom';
import { FiShare } from 'react-icons/fi';
import Loader from '../Loader/Loader';
import NoData from '../NoData/NoData';
import { nullToEmpty } from '../../utils/csvExport';

const PhoneNumber = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const dispatch = useDispatch();
  const location = useLocation();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const userId = useSelector((state) => state.AdminAuthData.userId);

  const phoneNumberData = useSelector((state) => state.WorkSiteData.getWorksitePhoneNumbersData);
  const phoneNumberErrorData = useSelector((state) => state.WorkSiteData.getWorksitePhoneNumbersError);

  const postPhoneNumberData = useSelector((state) => state.WorkSiteData.postPhoneNumberData);
  const postPhoneNumberError = useSelector((state) => state.WorkSiteData.postPhoneNumberError);
  const editPhoneNumberData = useSelector((state) => state.WorkSiteData.editPhoneNumberData);
  const editPhoneNumberError = useSelector((state) => state.WorkSiteData.editPhoneNumberError);
  const deletePhoneNumberData = useSelector((state) => state.WorkSiteData.deletePhoneNumberData);
  const deletePhoneNumberError = useSelector((state) => state.WorkSiteData.deletePhoneNumberError);
  const worksiteId = useSelector((state) => state.AdminAuthData.userId);

  const [phoneNumberWithIndex, setPhoneNumberWithIndex] = useState([])
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentPhoneNumberId, setCurrentPhoneNumberId] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  // const [exportPhoneNumbersData, setExportPhoneNumbersData] = useState(true);

  // Slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false]);
  const [slideUpWidth, setSlideUpWidth] = useState('500');

  const [csvProps, setCsvProps] = useState(null);
  const csvPropsRef = useRef(null);

  // Callback to get CSV props from ToolkitProvider
  // useEffect(() => {
  //   if (csvPropsRef.current) {
  //     setCsvProps(csvPropsRef.current);
  //   }
  // }, [csvPropsRef.current]);

  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth('600');
    if (slideUpModalSize[1]) setSlideUpWidth('500');
    if (slideUpModalSize[2]) setSlideUpWidth('300');
  }, [slideUpModalSize]);

  // GET Actions
  useEffect(() => {
    setIsLoading(true);
    if (userId) {
      dispatch(getWorksitePhoneNumbers(jwtToken, worksiteId));
    }
  }, [userId])

  useEffect(() => {
    const data = phoneNumberData;
    if (location?.pathname === "/worksite/phone-number-unverified") {
      const unVerifiedData = data?.unverifiedPhoneNumbers?.filter((item) => Number(item?.verified) === 0).map((phoneNo, i) => (
        { ...phoneNo, index: i + 1 }));
      setPhoneNumberWithIndex(unVerifiedData);
    } else if (location?.pathname === "/worksite/phone-number-verified") {
      const verifiedData = data?.verifiedPhoneNumbers?.filter((item) => Number(item?.verified) === 1).map((phoneNo, i) => (
        { ...phoneNo, index: i + 1 }));
      setPhoneNumberWithIndex(verifiedData);
    } else {
      setPhoneNumberWithIndex(data?.allPhoneNumbers?.map((phoneNo, i) => (
        { ...phoneNo, index: i + 1 }
      )));
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [phoneNumberData]);

  useEffect(() => {
    if (phoneNumberErrorData && Object.keys(phoneNumberErrorData)?.length > 0) {
      dispatch(resetWorkSitePhoneNumbersErrorData());
      setIsLoading(false);
    }
  }, [phoneNumberErrorData]);

  // POST Actions
  useEffect(() => {
    if (postPhoneNumberData && Object.keys(postPhoneNumberData)?.length > 0) {
      setPhoneNumber('');
      setIsPhoneNumberVerified(false);
      toast.success('Phone number added successfully');
      setSlideUpVisible(false);
      dispatch(getWorksitePhoneNumbers(jwtToken, worksiteId));
      dispatch(resetPostPhoneNumberData());
    }
  }, [postPhoneNumberData]);

  useEffect(() => {
    if (postPhoneNumberError && Object.keys(postPhoneNumberError)?.length > 0) {
      toast.error(postPhoneNumberError.message);
      dispatch(resetPostPhoneNumberErrorData());
    }
  }, [postPhoneNumberError]);

  // PATCH Actions
  useEffect(() => {
    if (editPhoneNumberData && Object.keys(editPhoneNumberData)?.length > 0) {
      toast.success('Phone number updated successfully');
      setSlideUpVisible(false);
      dispatch(getWorksitePhoneNumbers(jwtToken, worksiteId));
      dispatch(resetEditPhoneNumberData());
    }
  }, [editPhoneNumberData]);

  useEffect(() => {
    if (editPhoneNumberError && Object.keys(editPhoneNumberError)?.length > 0) {
      toast.error(editPhoneNumberError.message);
      dispatch(resetEditPhoneNumberErrorData());
    }
  }, [editPhoneNumberError]);

  // DELETE Actions
  useEffect(() => {
    if (deletePhoneNumberData && Object.keys(deletePhoneNumberData)?.length > 0) {
      toast.success('Phone number deleted successfully');
      dispatch(getWorksitePhoneNumbers(jwtToken, worksiteId));
      dispatch(resetDeletePhoneNumberData());
    }
  }, [deletePhoneNumberData]);

  useEffect(() => {
    if (deletePhoneNumberError && Object.keys(deletePhoneNumberError)?.length > 0) {
      toast.error(deletePhoneNumberError.message);
      dispatch(resetDeletePhoneNumberErrorData());
    }
  }, [deletePhoneNumberError]);


  const handleSearchChange = (e) => {
  const value = e.target.value.toLowerCase();
  setSearchValue(value);
  
  const { pathname } = window.location || {};
  
  let filteredPhoneNumberData = [];
  
  if (pathname.includes('phone-number-unverified')) {
    filteredPhoneNumberData = phoneNumberData?.unverifiedPhoneNumbers || [];
  } else if (pathname.includes('phone-number-verified')) {
    filteredPhoneNumberData = phoneNumberData?.verifiedPhoneNumbers || [];
  } else {
    filteredPhoneNumberData = phoneNumberData?.allPhoneNumbers || [];
  }

  if (value === '') {
    setPhoneNumberWithIndex(filteredPhoneNumberData.map((record, i) => ({ ...record, index: i + 1 })));
  } else {
    const filteredData = filteredPhoneNumberData.filter(data =>
      data.phoneNumber?.toLowerCase().includes(value)
    ).map((record, i) => ({ ...record, index: i + 1 }));
  
    setPhoneNumberWithIndex(filteredData);
  }
};

  const handleInputChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.checked === true ? 1 : 0;
    setIsPhoneNumberVerified(value);
  }

  const handleAddOrEdit = () => {
    const phoneNumberRegex = /^\+?\d+$/;
    const formattedPhoneNumber = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`
    if (formattedPhoneNumber === '' || !phoneNumberRegex.test(formattedPhoneNumber)) {
      return toast.error('Please enter a valid phone number');
    }
    if (isEditMode) {
      dispatch(editPhoneNumberByWorksite(jwtToken, currentPhoneNumberId, { phoneNumber: formattedPhoneNumber, verified: isPhoneNumberVerified, worksiteId: worksiteId }));
    } else {
      dispatch(postPhoneNumberByWorksite(jwtToken, { phoneNumber: formattedPhoneNumber, verified: isPhoneNumberVerified, worksiteId: worksiteId}));
    }
  };

  const handleAddClick = () => {
    setIsEditMode(false);
    setPhoneNumber('');
    setIsPhoneNumberVerified(false);
    setSlideUpVisible(true);
  }

  const handleEditClick = (row) => {
    setPhoneNumber(row.phoneNumber);
    setIsPhoneNumberVerified(row.verified)
    setIsEditMode(true);
    setCurrentPhoneNumberId(row.id);
    setSlideUpVisible(true);
  };

  const handleDeleteClick = (row) => {
    setCurrentPhoneNumberId(row.id);
    setSlideUpVisibleForDelete(true);
  };

  const handleDelete = () => {
    if (currentPhoneNumberId) {
      dispatch(deletePhoneNumberByWorksite(jwtToken, currentPhoneNumberId));
      setSlideUpVisibleForDelete(false);
    }
  };


  const tableColumns = [
    {
      dataField: 'index',
      text: '#',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    // {
    //   dataField: 'id',
    //   text: 'ID',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    // },
    {
      dataField: 'phoneNumber',
      text: 'Phone No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    // {
    //   dataField: 'verified',
    //   text: 'Verified',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    //   formatter: (cell, row) => (
    //     <div>
    //       {cell === 1 ? (
    //         <div className="btn btn-outline-success rounded-circle cusicon">
    //           <i className="pg-icon">tick</i>
    //         </div>
    //       ) : (
    //         <div className="btn btn-outline-danger rounded-circle cusicon">
    //           <i className="pg-icon">close</i>
    //         </div>
    //       )}
    //     </div>
    //   ),
    // },
    {
      dataField: 'verified',
      text: 'Verified',
      sort: true,
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell, row) => {
        // Apply custom rendering logic for the table
        return (
          <div>
            {cell === 1 ? (
              <div className="btn btn-outline-success rounded-circle cusicon">
                <i className="pg-icon">tick</i>
              </div>
            ) : (
              <div className="btn btn-outline-danger rounded-circle cusicon">
                <i className="pg-icon">close</i>
              </div>
            )}
          </div>
        );
      },
      csvExport: true,  // Enable export for this field
      csvFormatter: (cell, row) => {
        // Use plain text for export (no custom UI)
        return cell === 1 ? 'Yes' : 'No';
      },
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell) => {
        if (cell) {
          return new Date(cell).toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
          });
        } else {
          return '';
        }
      },
      csvFormatter: (cell) => {
        if (cell) {
          return new Date(cell).toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
          });
        } else {
          return '';
        }
      },
      sort: true,
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();
        if (order === 'asc') {
          return dateA - dateB;
        }
        return dateB - dateA;
    }
    },
    // {
    //   dataField: 'updatedAt',
    //   text: 'Updated At',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    // },
    {
      text: 'Actions',
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvExport: false,
      formatter: (cell, row) => (
        <div>
          <Button type="primary" utilclass="m-r-5" iconposition="center" onClick={() => handleEditClick(row)}>
            <i className="pg-icon">edit</i>
            {/* <span className="">Edit</span> */}
          </Button>
          <Button type="danger" iconposition="center" onClick={() => handleDeleteClick(row)}>
            <i className="pg-icon">trash</i>
            {/* Delete */}
          </Button>
        </div>
      ),
    },
  ];

  // useEffect(() => {
  //   setExportPhoneNumbersData(nullToEmpty(phoneNumberWithIndex))
  // }, [phoneNumberWithIndex])

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="custom-dropdown-container">
      <select
        value={currSizePerPage}
        onChange={(e) => onSizePerPageChange(Number(e.target.value))}
        className="custom-form-control"
      >
        {options.map(option => (
          <option key={option.text} value={option.page}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
  
  const options = {
    sizePerPageRenderer
  };  

  return (
    <div className="page-container">
      <div className="page-content-wrapper">
        <div className="content">
          {/* <div className="jumbotron" data-pages="parallax"></div> */}

          <PageContainer className="bg-white container">
            <div className="card card-transparent">
              <div className="card-header  d-flex justify-content-between mb-3 titWbtn">
                <h3 class="text-primary no-margin text-uppercase">{location?.pathname === "/worksite/phone-number-verified" ? "Verified Phone Numbers" : (location?.pathname === "/worksite/phone-number-unverified") ? "Unverified Phone Numbers" : "All Phone Numbers"}</h3>
                <div className="export-options-container">
                  <div className="exportOptions">
                    <div className="DTTT d-flex align-items-center">
                      <button aria-label="" className="btn btn-primary px-4 m-r-10" type="button" onClick={handleAddClick}>
                        + Add Phone Number
                      </button>
                      {csvProps && phoneNumberWithIndex?.length > 0 && (
                        <ExportCSVButton {...csvProps}><i className="pg-icon mr-1"><FiShare size={'15'} /></i> Export Phone Number CSV</ExportCSVButton>
                      )}
                      {slideUpVisibleForDelete ?
                        <SlideUpModal visible={slideUpVisibleForDelete} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForDelete(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-top">
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForDelete(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                                <h5>Are you sure you want to delete?</h5>
                              </div>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-secondary btn-block" onClick={() => setSlideUpVisibleForDelete(false)}>
                                      Cancel
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-danger btn-block" onClick={handleDelete}>
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}

                      {slideUpVisible ?
                        <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">

                                <h5>{isEditMode ? 'Edit Phone Number' : 'Add Phone Number'}</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body p-5">
                                <form role="form">
                                  <div class="input-group mb-3">
                                    <input type="text" className="form-control" value={phoneNumber} onChange={handleInputChange} placeholder="Enter phone number" />
                                    <span class={isPhoneNumberVerified ? "input-group-text bg-success text-white" : "input-group-text  bg-secondary"} id="basic-addon2">
                                      <label className="form-check-label mt-1" htmlFor="verifiedCheckbox">
                                        <input type="checkbox" className="form-check-input mr-2" id="verifiedCheckbox" checked={isPhoneNumberVerified} onChange={handleCheckboxChange} />
                                        {isPhoneNumberVerified ? "Verified" : 'Verify'}
                                      </label>
                                    </span>
                                  </div>
                                </form>
                              </div>

                              <div className='modal-footer'>
                                <button aria-label="" type="button" className="btn btn-primary py-2 px-3" onClick={handleAddOrEdit}>
                                  {isEditMode ? 'Update' : '+ Add'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="DTTT btn-group d-flex align-items-center">
                <div className="input-group m-r-15 w-auto col-md-3 p-l-0">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by phone number" value={searchValue} onChange={handleSearchChange} />
                </div>
              </div>
              {!isLoading ? <div className="card-body custable">
                {phoneNumberWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={phoneNumberWithIndex}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'phoneNumber-data.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      }
                      return (<React.Fragment>
                        <BootstrapTable
                          {...props.baseProps}
                          hover={true}
                          bootstrap4
                          bordered={false}
                          condensed={true}
                          striped={true}
                          pagination={paginationFactory(options)}
                        />
                      </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                )}
              </div> : <Loader />}
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;
