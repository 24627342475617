import React, { useState, useEffect, useRef } from 'react';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import PageContainer from '../UIElements/Containers';

import { deleteCorporateRecord, getAllCorporateRecords, editCorporateRecordByAdmin, postCorporateRecord, resetDeleteCorporateRecordData, resetDeleteCorporateRecordErrorData, resetEditCorporateRecordData, resetCorporateRecordsErrorData, resetPostCorporateRecordData, resetPostCorporateRecordErrorData } from '../../api-actions/corporateDataAction';
import { useDispatch, useSelector } from 'react-redux';
import SlideUpModal from '../Modals/SlideUpModal';
import { toast } from 'react-toastify';
import Button from '../UIElements/Button/UtilsButton';
import NoData from '../NoData/NoData';
import { FiShare } from 'react-icons/fi';
import Loader from '../Loader/Loader';

const Corporate = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const dispatch = useDispatch();
  const corporateRecordEmptyState = { corporateName: '', corporateEmail: '', corporatePhoneNumber: '', corporateTollFreeNumber: '', corporateWorkOrderStartRange: '', corporateWorkOrderEndRange: '' };

  const allCorporateRecordsData = useSelector((state) => state.CorporateData.allCorporateRecordsData);
  const corporateRecordsErrorData = useSelector((state) => state.CorporateData.getAllCorporateRecordsError);
  const postCorporateRecordData = useSelector((state) => state.CorporateData.postCorporateRecordData);
  const postCorporateRecordError = useSelector((state) => state.CorporateData.postCorporateRecordError);
  const editCorporateRecordData = useSelector((state) => state.CorporateData.editCorporateRecordData);
  const editCorporateRecordError = useSelector((state) => state.CorporateData.editCorporateRecordError);
  const deleteCorporateRecordData = useSelector((state) => state.CorporateData.deleteCorporateRecordData);
  const deleteCorporateRecordError = useSelector((state) => state.CorporateData.deleteCorporateRecordError);

  const [corporateWithIndex, setCorporateWithIndex] = useState([])
  const [corporateRecord, setCorporateRecord] = useState(corporateRecordEmptyState)
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [currentCorporateId, setCurrentCorporateId] = useState(null);
  const [csvProps, setCsvProps] = useState(null);
  const csvPropsRef = useRef(null);
  const [exportCorporateData, setExportCorporateData] = useState()

  const [filters, setFilters] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    tollNumber: ''
  });

  useEffect(() => {
    dispatch(getAllCorporateRecords(jwtToken));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
    const filteredData = allCorporateRecordsData.filter(data => {
      const nameMatch = filters.name === '' || data.name?.toLowerCase().includes(filters.name.toLowerCase());
      const phoneNumberMatch = filters.phoneNumber === '' || data.phoneNumber?.includes(filters.phoneNumber);
      const tollNumberMatch = filters.tollNumber === '' || data.tollFreeNumber?.includes(filters.tollNumber);
      const emailMatch = filters.email === '' || data.email?.includes(filters.email);

      return (
        nameMatch &&
        phoneNumberMatch &&
        tollNumberMatch &&
        emailMatch
      );
    }).map((record, i) => ({ ...record, index: i + 1 }));

    setTimeout(() => {
      setIsLoading(false)
    }, 1000);

    setCorporateWithIndex(filteredData);
  }, [filters, allCorporateRecordsData]);

  useEffect(() => {
    if (corporateRecordsErrorData && Object.keys(corporateRecordsErrorData)?.length > 0) {
      dispatch(resetPostCorporateRecordErrorData());
    }
  }, [corporateRecordsErrorData]);

  // useEffect(() => {
  //   if (csvPropsRef.current) {
  //     setCsvProps(csvPropsRef.current);
  //   }
  // }, [csvPropsRef.current]);

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="custom-dropdown-container">
      <select
        value={currSizePerPage}
        onChange={(e) => onSizePerPageChange(Number(e.target.value))}
        className="custom-form-control"
      >
        {options.map(option => (
          <option key={option.text} value={option.page}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
  
  const options = {
    sizePerPageRenderer
  }; 

  useEffect(() => {
    if (postCorporateRecordData && Object.keys(postCorporateRecordData)?.length > 0) {
      setCorporateRecord(corporateRecordEmptyState)
      toast.success('Corporate record added successfully')
      setSlideUpVisible(false)
      dispatch(getAllCorporateRecords(jwtToken));
      dispatch(resetPostCorporateRecordData())
    }
  }, [postCorporateRecordData])

  useEffect(() => {
    if (postCorporateRecordError && Object.keys(postCorporateRecordError)?.length > 0) {
      toast.error(postCorporateRecordError.message)
      dispatch(resetPostCorporateRecordErrorData());
    }
  }, [postCorporateRecordError]);

  useEffect(() => {
    if (editCorporateRecordData && Object.keys(editCorporateRecordData)?.length > 0) {
      setCorporateRecord(corporateRecordEmptyState);
      toast.success('Corporate record updated successfully');
      setSlideUpVisible(false);
      dispatch(getAllCorporateRecords(jwtToken));
      dispatch(resetEditCorporateRecordData());
    }
  }, [editCorporateRecordData]);

  useEffect(() => {
    if (editCorporateRecordError && Object.keys(editCorporateRecordError)?.length > 0) {
      toast.error(editCorporateRecordError.message);
      dispatch(resetCorporateRecordsErrorData());
    }
  }, [editCorporateRecordError]);

  useEffect(() => {
    if (deleteCorporateRecordData && Object.keys(deleteCorporateRecordData)?.length > 0) {
      toast.success('Corporate record deleted successfully');
      dispatch(getAllCorporateRecords(jwtToken));
      dispatch(resetDeleteCorporateRecordData());
    }
  }, [deleteCorporateRecordData]);

  useEffect(() => {
    if (deleteCorporateRecordError && Object.keys(deleteCorporateRecordError)?.length > 0) {
      toast.error(deleteCorporateRecordError.message);
      dispatch(resetDeleteCorporateRecordErrorData());
    }
  }, [deleteCorporateRecordError]);

  //slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false]);
  const [slideUpWidth, setSlideUpWidth] = useState("500");
  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth("600");
    if (slideUpModalSize[1]) setSlideUpWidth("700");
    if (slideUpModalSize[2]) setSlideUpWidth("300");
  }, [slideUpModalSize]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCorporateRecord((prevCorporateRecord) => ({
      ...prevCorporateRecord,
      [name]: value
    }));
  }

  const handleAddOrEdit = () => {
    const missingFields = [];
    if (!corporateRecord.corporateName) missingFields.push('Corporate Name');
    if (!corporateRecord.corporatePhoneNumber) missingFields.push('Corporate Phone Number');
    if (!corporateRecord.corporateTollFreeNumber) missingFields.push('Corporate Toll Free Number');
    if (!corporateRecord.corporateEmail) missingFields.push('Corporate Email');
    if (!isEditMode) {
      if (!corporateRecord.corporatePassword) missingFields.push('Corporate Password');
    }

    if (missingFields.length > 0) {
      return toast.error('Please fill all required fields')
    }
    
    if (isEditMode) {
      dispatch(editCorporateRecordByAdmin(jwtToken, currentCorporateId, corporateRecord));
    } else {
      dispatch(postCorporateRecord(jwtToken, corporateRecord));
    }
  };

  const handleAddClick = () => {
    setIsEditMode(false);
    setCorporateRecord(corporateRecordEmptyState);
    setSlideUpVisible(true);
  }

  const handleEditClick = (row) => {
    setIsEditMode(true)
    setCurrentCorporateId(row.id)
    setSlideUpVisible(true)
    setCorporateRecord({
      corporateName: row.name,
      corporateEmail: row.email,
      corporatePhoneNumber: row.phoneNumber,
      corporateTollFreeNumber: row.tollFreeNumber,
      corporateWorkOrderStartRange: row.workOrderStartRange,
      corporateWorkOrderEndRange: row.workOrderEndRange,
    })
  }

  const handleDeleteClick = (row) => {
    setCurrentCorporateId(row.id)
    setSlideUpVisibleForDelete(true)
  }

  const handleDelete = () => {
    if (currentCorporateId) {
      dispatch(deleteCorporateRecord(jwtToken, currentCorporateId))
      setSlideUpVisibleForDelete(false)
    }
  }

  const handleSearchChange = (value, type) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [type]: value
    }));
  };

  // CSV Export
  useEffect(() => {
    setExportCorporateData(nullToEmpty(corporateWithIndex))
  }, [corporateWithIndex])

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // 12-hour time format
    };
  
    // Format the date
    const formattedDate = date.toLocaleString('en-US', options);
    
    return formattedDate;
  };

  const nullToEmpty = (data) => {
    return data?.map((item, i) => {
      let updatedItem = {};

      for (let key in item) {
        updatedItem[key] = item[key] === null ? "" : item[key];
        if ((key === 'createdAt') && updatedItem[key]) {
          updatedItem[key] = formatDate(updatedItem[key]);
        }
      }

      return {
        ...updatedItem,
        index: i + 1
      };
    });
  }

  const tableColumns = [
    {
      dataField: 'index',
      text: '#',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    // {
    //   dataField: 'id',
    //   text: 'ID',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   //   sort: true,
    // },
    {
      dataField: 'name',
      text: 'Name',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    {
      dataField: 'email',
      text: 'Email',
      headerStyle: { backgroundColor: '#f0f0f073' },
      //   sort: true,
    },
    {
      dataField: 'phoneNumber',
      text: 'Phone Number',
      headerStyle: { backgroundColor: '#f0f0f073' },
      //   sort: true,
    },
    {
      dataField: 'tollFreeNumber',
      text: 'Toll Free Number',
      headerStyle: { backgroundColor: '#f0f0f073' },
      //   sort: true,
    },
    {
      dataField: 'workOrderStartRange',
      text: 'Work Order Start Range',
      headerStyle: { backgroundColor: '#f0f0f073' },
      //   sort: true,
    },
    {
      dataField: 'workOrderEndRange',
      text: 'Work Order End Range',
      headerStyle: { backgroundColor: '#f0f0f073' },
      //   sort: true,
    },
    {
      dataField: 'createdAt',
      text: 'Created At',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    // {
    //   dataField: 'updatedAt',
    //   text: 'Updated At',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    //   sort: true,
    // },
    {
      text: 'Actions',
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvExport: false,
      formatter: (cell, row) => (
        <div>
          <Button type="primary" utilclass="m-r-5" iconposition="center" onClick={() => handleEditClick(row)}>
            <i className="pg-icon">edit</i>
            {/* <span className="">Edit</span> */}
          </Button>
          <Button type="danger" iconposition="center" onClick={() => handleDeleteClick(row)}>
            <i className="pg-icon">trash</i>
            {/* Delete */}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="page-container">
      <div className="page-content-wrapper ">
        <div className="content ">
          {/* <div className="jumbotron" data-pages="parallax"></div> */}

          <PageContainer className="bg-white container">
            <div className="card card-transparent">
              <div className="card-header  d-flex justify-content-between mb-3 titWbtn">
                <h3 class="text-primary no-margin text-uppercase">Corporate</h3>
                <div className="export-options-container">
                  <div className="exportOptions">
                    <div className="DTTT">
                      <button aria-label="" className="btn btn-primary btn-lg m-r-10" type="submit" onClick={handleAddClick}>
                        + Add Corporate
                      </button>
                      {csvProps && (
                        <ExportCSVButton {...csvProps}><i className="pg-icon m-r-10"><FiShare size={'15'} /></i>Export Corporate CSV</ExportCSVButton>
                      )}
                      {slideUpVisibleForDelete ?
                        <SlideUpModal visible={slideUpVisibleForDelete} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForDelete(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-top">
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForDelete(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                                <h5>Are you sure you want to delete?</h5>
                              </div>
                              <div className="modal-body">
                                <div className="row">
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-secondary btn-block" onClick={() => setSlideUpVisibleForDelete(false)}>
                                      Cancel
                                    </button>
                                  </div>
                                  <div className="col-md-6">
                                    <button aria-label="" type="button" className="btn btn-danger btn-block" onClick={handleDelete}>
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}

                      {slideUpVisible ?
                        <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                          <div className="modal-content-wrapper">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5>{isEditMode ? 'Edit corporate record' : 'Add corporate record'}</h5>
                                <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                                  <i className="pg-icon">close</i>
                                </button>
                              </div>
                              <div className="modal-body p-5">
                                <form role="form">
                                  <div class="input-group flex-column">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label for='corporateName'>Corporate Name<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Name' : 'Enter Name'} value={corporateRecord.corporateName} onChange={handleInputChange} name='corporateName' id='corporateName' />
                                      </div>

                                      <div className="col-md-6">
                                        <label for='corporatePhoneNumber'>Corporate Phone Number<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Phone Number' : 'Enter Phone Number'} value={corporateRecord.corporatePhoneNumber} onChange={handleInputChange} name='corporatePhoneNumber' id='corporatePhoneNumber' />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6">
                                        <label for='workOrderStartRange' className='mt-3'>Work Order Start Range</label>
                                        <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Work Order Start Range' : 'Enter Work Order Start Range'} value={corporateRecord.corporateWorkOrderStartRange} onChange={handleInputChange} name='corporateWorkOrderStartRange' id='workOrderStartRange' />
                                      </div>

                                      <div className="col-md-6">
                                        <label for='workOrderEndRange' className='mt-3'>Work Order End Range</label>
                                        <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Work Order End Range' : 'Enter Work Order End Range'} value={corporateRecord.corporateWorkOrderEndRange} onChange={handleInputChange} name='corporateWorkOrderEndRange' id='workOrderEndRange' />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6">
                                        <label for='corporateTollFreeNumber' className='mt-3'>Toll Free Number<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Toll Free Number' : 'Enter Toll Free Number'} value={corporateRecord.corporateTollFreeNumber} onChange={handleInputChange} name='corporateTollFreeNumber' id='corporateTollFreeNumber' />
                                      </div>

                                      <div className="col-md-6">
                                        <label for='corporateEmail' className='mt-3'>Email<span style={{ color: 'red' }}>*</span></label>
                                        <input type="text" className="form-control w-100" placeholder={isEditMode ? 'Update Email' : 'Enter Email'} value={corporateRecord.corporateEmail} onChange={handleInputChange} name='corporateEmail' id='corporateEmail' />
                                      </div>
                                    </div>

                                    {!isEditMode && (
                                      <>
                                        <label htmlFor='corporatePassword' className='mt-3'>Password<span style={{ color: 'red' }}>*</span></label>
                                        <input type="password" className="form-control w-100" placeholder='Enter Password' value={corporateRecord.corporatePassword} onChange={handleInputChange} name='corporatePassword' id='corporatePassword' />
                                      </>
                                    )}
                                  </div>
                                </form>
                              </div>
                              <div className='modal-footer'>
                                <button aria-label="" type="button" className="btn btn-primary" onClick={handleAddOrEdit}>
                                  {isEditMode ? 'Update' : '+ Add'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </SlideUpModal> : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-start">
                <div className="input-group col-md-3 p-l-0">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by name"
                    value={filters.name} onChange={(e) => handleSearchChange(e.target.value, 'name')}
                  />
                </div>
                <div className="input-group col-md-3">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by phone number"
                    value={filters.phoneNumber} onChange={(e) => handleSearchChange(e.target.value, 'phoneNumber')}
                  />
                </div>
                <div className="input-group col-md-3">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by email"
                    value={filters.email} onChange={(e) => handleSearchChange(e.target.value, 'email')}
                  />
                </div>
                <div className="input-group col-md-3">
                  <span className="input-group-text input-date-icon-pre"><i className="pg-icon">search</i></span>
                  <input type="text" className="form-control p-l-10" placeholder="Search by toll free number"
                    value={filters.tollNumber} onChange={(e) => handleSearchChange(e.target.value, 'tollNumber')}
                  />
                </div>
              </div>
              <div className="card-body custable">
                {!isLoading ? corporateWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={exportCorporateData}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'corporate-data.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      }
                      return (
                        <React.Fragment>
                          <BootstrapTable
                            {...props.baseProps}
                            hover={true}
                            bootstrap4
                            bordered={false}
                            condensed={true}
                            striped={true}
                            pagination={paginationFactory(options)}
                          // cellEdit={cellEditFactory({
                          //   mode: 'click',
                          //   blurToSave: true,
                          // })}
                          />
                        </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                ) : <Loader />}
              </div>
            </div>
          </PageContainer >
        </div >
      </div >
    </div >
  );
};

export default Corporate;
