import React, { useState, useEffect, useRef } from 'react';
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import Button from '../UIElements/Button/UtilsButton';
import PageContainer from '../UIElements/Containers';
import SlideUpModal from '../Modals/SlideUpModal';
import { toast } from 'react-toastify';
import GroupSelect from "../Forms/Elements/GroupSelect";
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import {
  getAllCallRecords,
  getWorkSitesForCorporate,
  resetGetCallRecordErrorData,
  deleteCallRecord,
  postCallRecord,
  editCallRecord,
  resetDeleteCallRecordData,
  resetDeleteCallRecordErrorData,
  resetEditCallRecordData,
  resetEditCallRecordErrorData,
  resetPostCallRecordData,
  resetPostCallRecordErrorData,
  getCallRecordingUrl,
} from '../../api-actions/corporateDataAction';
import {
  resetWorkSiteErrorData,
} from '../../api-actions/workSiteDataAction';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { RiShareBoxFill } from 'react-icons/ri';
import { FiShare } from 'react-icons/fi';
import Loader from '../Loader/Loader';
import NoData from '../NoData/NoData';
import { Link } from 'react-router-dom';

const CallRecords = () => {
  const { ExportCSVButton } = CSVExport;
  const jwtToken = localStorage.getItem('jwtToken');
  const callRecordEmptyState = {
    worksiteId: '',
    phoneNumber: '',
    workerName: '',
    workerDob: '',
    workerStatus: '',
    workOrderNumber: '',
    checkIn: '',
    checkOut: '',
  };
  const newCallRecordEmptyState = {
    worksiteId: '',
    phoneNumber: '',
    workerName: '',
    workerDob: '',
    workerStatus: '',
    workOrderNumber: '',
    checkIn: '',
    checkOut: '',
  };

  let endDate = new Date();
  endDate.setDate(endDate.getDate() + 30);

  const [workerStatus, setWorkerStatus] = useState('');

  const dispatch = useDispatch();
  const postCallRecordData = useSelector((state) => state.AdminData.postCallRecordData);
  const postCallRecordError = useSelector((state) => state.AdminData.postCallRecordError);

  const userId = useSelector((state) => state.AdminAuthData.userId);
  const callRecords = useSelector((state) => state.CorporateData.getAllCallRecordsData);
  const callRecordsErrorData = useSelector((state) => state.CorporateData.getAllCallRecordsError);
  const workSitesForCorporateData = useSelector((state) => state.CorporateData.getWorkSitesForCorporateData);
  const workSitesForCorporateError = useSelector((state) => state.CorporateData.getWorkSitesForCorporateError);

  const editCallRecordData = useSelector((state) => state.AdminData.editCallRecordData);
  const editCallRecordError = useSelector((state) => state.AdminData.editCallRecordError);
  const deleteCallRecordData = useSelector((state) => state.AdminData.deleteCallRecordData);
  const deleteCallRecordError = useSelector((state) => state.AdminData.deleteCallRecordError);

  const [callRecordsWithIndex, setCallRecordsWithIndex] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [startDateRange, setStartDateRange] = useState();
  const [endDateRange, setEndDateRange] = useState();
  const [currentCallRecordId, setCurrentCallRecordId] = useState(null);
  const [callRecord, setCallRecord] = useState(callRecordEmptyState)
  const [newCallRecord, setNewCallRecord] = useState(newCallRecordEmptyState);
  const [worksiteOptionsForFilter, setWorksiteOptionsForFilter] = useState([]);
  const [worksiteOptions, setWorksiteOptions] = useState([]);
  const [worksiteId, setWorksiteId] = useState('');
  const workerStatusFilterOptions = [{ value: 'all', label: 'All Status' }, { value: 'completed', label: 'Completed' }, { value: 'additional work found', label: 'Additional Work Found' }, { value: 'requires part', label: 'Requires Part' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires authorization', label: 'Requires Authorization' }]
  const workerStatusOptions = [{ value: 'completed', label: 'Completed' }, { value: 'additional work found', label: 'Additional Work Found' }, { value: 'requires part', label: 'Requires Part' }, { value: 'requires follow-up', label: 'Requires Follow-up' }, { value: 'requires authorization', label: 'Requires Authorization' }]


  const [audioUrl, setAudioUrl] = useState('');
  const [recordingUrl, setRecordingUrl] = useState('');
  const [audioSlideUpVisible, setAudioSlideUpVisible] = useState(false);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [exportCallRecordsData, setExportCallRecordsData] = useState()

  // Create call records modal
  const [slideUpVisibleForCreate, setSlideUpVisibleForCreate] = useState(false);
  const [slideUpVisibleForViewData, setSlideUpVisibleForViewData] = useState(false);
  const [viewData, setViewData] = useState(false);

  const [csvProps, setCsvProps] = useState(null);
  const csvPropsRef = useRef(null);

  const [filters, setFilters] = useState({
    searchValue: '',
    phoneNumber: '',
    workerName: '',
    workerStatus: '',
    technicianPhoneNumber: ''
  });

  //slide-up modal
  const [slideUpVisible, setSlideUpVisible] = useState(false);
  const [slideUpVisibleForDelete, setSlideUpVisibleForDelete] = useState(false);
  const [slideUpModalSize, setSlideUpModalSize] = useState([false, true, false,]);
  const [slideUpWidth, setSlideUpWidth] = useState("500");
  useEffect(() => {
    if (slideUpModalSize[0]) setSlideUpWidth("600");
    if (slideUpModalSize[1]) setSlideUpWidth("700");
    if (slideUpModalSize[2]) setSlideUpWidth("300");
  }, [slideUpModalSize]);

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="custom-dropdown-container">
      <select
        value={currSizePerPage}
        onChange={(e) => onSizePerPageChange(Number(e.target.value))}
        className="custom-form-control"
      >
        {options.map(option => (
          <option key={option.text} value={option.page}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
  
  const options = {
    sizePerPageRenderer
  };


  // GET Actions
  useEffect(() => {
    setIsLoading(true);
    if (userId) {
      dispatch(getAllCallRecords(jwtToken, userId));
      dispatch(getWorkSitesForCorporate(jwtToken, userId));
    }
  }, [userId]);

  useEffect(() => {
    if (recordingUrl?.length > 0 && !audioSlideUpVisible) {
      const fetchRecording = async () => {
        try {
          const jwtToken = localStorage.getItem('jwtToken');
          setAudioSlideUpVisible(true);
          const url = await dispatch(getCallRecordingUrl(jwtToken, recordingUrl));
          if (url?.data) {
            const audioBlob = new Blob([url?.data], { type: 'audio/mpeg' });
            const audioObjectUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioObjectUrl);
            setRecordingUrl('');
          } else {
            toast.error('Try again later');
            setAudioSlideUpVisible(false);
            setRecordingUrl("");
            setAudioUrl("");
            setName("");
          }
        } catch (error) {
          toast.error('Try again later');
        }
      };
      fetchRecording();
    }
  }, [recordingUrl, audioSlideUpVisible, dispatch]);


  useEffect(() => {
    if (workSitesForCorporateData) {
      setWorksiteOptions([]);
      setWorksiteOptionsForFilter([]);
      const worksiteData = workSitesForCorporateData?.map((record) => ({
        value: record?.id,
        label: record?.storeName,
      }));

      setWorksiteOptions(Array.from(new Map(worksiteData.map(item => [item.value, item])).values()))

      const uniqueArray = Array.from(new Map(worksiteData.map(item => [item.value, item])).values());
      if (uniqueArray?.length > 0) {
        uniqueArray.unshift({ value: '', label: 'All Stores' });
      }
      setWorksiteOptionsForFilter(uniqueArray);
    }

  }, [workSitesForCorporateData]);

  useEffect(() => {
    if (callRecordsErrorData && Object.keys(callRecordsErrorData)?.length > 0) {
      dispatch(resetGetCallRecordErrorData());
      setIsLoading(false)
    }
  }, [callRecordsErrorData]);

  useEffect(() => {
    if (workSitesForCorporateError && Object.keys(workSitesForCorporateError)?.length > 0) {
      dispatch(resetWorkSiteErrorData());
    }
  }, [workSitesForCorporateError]);

  // POST Actions
  useEffect(() => {
    if (postCallRecordData && Object.keys(postCallRecordData)?.length > 0) {
      toast.success('Call record added successfully');
      setSlideUpVisibleForCreate(false);
      setNewCallRecord(newCallRecordEmptyState)
      dispatch(getAllCallRecords(jwtToken, userId));
      dispatch(resetPostCallRecordData());
    }
  }, [postCallRecordData]);

  useEffect(() => {
    if (postCallRecordError && Object.keys(postCallRecordError)?.length > 0) {
      toast.error(postCallRecordError.message);
      dispatch(resetPostCallRecordErrorData());
    }
  }, [postCallRecordError]);



  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  useEffect(() => {
    const { phoneNumber, workerName, technicianPhoneNumber } = filters; // Extract phoneNumber and workerName filters

    let filteredData = callRecords;

    // Apply worksiteId filter if provided
    if (worksiteId?.length > 0) {
      filteredData = filteredData.filter(data => data.worksiteId === worksiteId);
    }

    if (startDateRange && endDateRange) {
      filteredData = filteredData.filter(data => {
        const createdAtDate = new Date(data.createdAt);
        return createdAtDate >= startDateRange && createdAtDate <= endDateRange;
      });
    }

    if (technicianPhoneNumber) {
      filteredData = filteredData.filter(data =>
        data.workerDob?.toLowerCase().includes(technicianPhoneNumber.toLowerCase())
      );
    }

    // Apply phoneNumber filter if provided
    if (phoneNumber) {
      filteredData = filteredData.filter(data =>
        data.phoneNumber?.toLowerCase().includes(phoneNumber.toLowerCase())

      );
    }

    // Apply workerName filter if provided
    if (workerName) {
      filteredData = filteredData.filter(data =>
        data.workerName?.toLowerCase().includes(workerName.toLowerCase())
      );
    }

    if (workerStatus && workerStatus !== 'all') {
      filteredData = filteredData.filter(data => data.workerStatus === workerStatus);
    }

    // Add index and update state
    setCallRecordsWithIndex(filteredData.map((record, i) => ({ ...record, index: i + 1 })));
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [filters, callRecords, worksiteId, workerStatus, startDateRange, endDateRange]);

  useEffect(() => {
    setExportCallRecordsData(nullToEmpty(callRecordsWithIndex))
  }, [callRecordsWithIndex])

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // 12-hour time format
    };

    // Format the date
    const formattedDate = date.toLocaleString('en-US', options);

    return formattedDate;
  };

  const nullToEmpty = (data) => {
    return data?.map((item, i) => {
      let updatedItem = {};

      for (let key in item) {
        updatedItem[key] = item[key] === null ? "" : item[key];
        if ((key === 'checkIn' || key === 'checkOut') && updatedItem[key]) {
          updatedItem[key] = formatDate(updatedItem[key]);
        }
      }

      return {
        ...updatedItem,
        index: i + 1
      };
    });
  }

  const handleDateRange = (event, picker) => {
    setStartDateRange(picker.startDate.toDate());
    setEndDateRange(picker.endDate.toDate());
  };

  // PATCH Actions
  useEffect(() => {
    if (editCallRecordData && Object.keys(editCallRecordData)?.length > 0) {
      setCallRecord(callRecordEmptyState);
      toast.success('Call record data updated successfully');
      setSlideUpVisible(false);
      dispatch(getAllCallRecords(jwtToken, userId));
      dispatch(resetEditCallRecordData());
    }
  }, [editCallRecordData]);

  useEffect(() => {
    if (editCallRecordError && Object.keys(editCallRecordError)?.length > 0) {
      toast.error(editCallRecordError.message);
      dispatch(resetEditCallRecordErrorData());
    }
  }, [editCallRecordError]);

  // DELETE Actions
  useEffect(() => {
    if (deleteCallRecordData && Object.keys(deleteCallRecordData)?.length > 0) {
      toast.success('Call record data deleted successfully');
      dispatch(getAllCallRecords(jwtToken, userId));
      dispatch(resetDeleteCallRecordData());
    }
  }, [deleteCallRecordData]);

  useEffect(() => {
    if (deleteCallRecordError && Object.keys(deleteCallRecordError)?.length > 0) {
      toast.error(deleteCallRecordError.message);
      dispatch(resetDeleteCallRecordErrorData());
    }
  }, [deleteCallRecordError]);

  // Callback to get CSV props from ToolkitProvider
  // useEffect(() => {
  //   if (csvPropsRef.current) {
  //     setCsvProps(csvPropsRef.current);
  //   }
  // }, [csvPropsRef.current]);

  const handleInputChange = (e, selectOption = null) => {
    if (selectOption) {
      setCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        [e.name]: selectOption
      }));
    } else {
      const { name, value } = e.target;
      setCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        [name]: value
      }));
    }
  };

  const newCallRecordInputChange = (e, selectOption = null) => {
    if (selectOption) {
      setNewCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        corporateId: userId,
        [e.name]: selectOption,
      }));
    } else {
      const { name, value } = e.target;
      setNewCallRecord((prevCallRecord) => ({
        ...prevCallRecord,
        corporateId: userId,
        [name]: value,
      }));
    }
  };

  const handleEditClick = (row) => {
    setCurrentCallRecordId(row.id)
    setSlideUpVisible(true)
    setCallRecord({
      worksiteId: row?.worksiteId,
      phoneNumber: row?.phoneNumber,
      workerName: row?.workerName,
      workerDob: row?.workerDob,
      workerStatus: row?.workerStatus,
      workOrderNumber: row?.workOrderNumber,
      checkIn: row?.checkIn ? new Date(row?.checkIn) : '',
      checkOut: row?.checkOut ? new Date(row?.checkOut) : '',
    });
  }

  const handleDeleteClick = (row) => {
    setCurrentCallRecordId(row.id)
    setSlideUpVisibleForDelete(true)
  }

  const closeModal = () => {
    setName('');
    setAudioUrl('');
    setAudioSlideUpVisible(false);
  };

  const handleAddCallRecords = async () => {
    setSlideUpVisibleForCreate(true)
  }

  const handleViewClick = (row) => {
    setViewData(row);
    setSlideUpVisibleForViewData(true);
  };

  const handleClickUrlOne = (row) => {
    if (!audioSlideUpVisible && row.checkInRecordingUrl) {
      setRecordingUrl(row.checkInRecordingUrl);
      setName(row.workerName);
    }
  };

  const handleClickUrlTwo = (row) => {
    if (!audioSlideUpVisible && row.checkOutRecordingUrl) {
      setRecordingUrl(row.checkOutRecordingUrl);
      setName(row.workerName);
    }
  };

  const handleCreate = () => {
    const missingFields = [];
    if (!newCallRecord.worksiteId) missingFields.push('Worksite');
    // if (!newCallRecord.phoneNumber) missingFields.push('Phone number');
    if (!newCallRecord.workerName) missingFields.push('Worker name');
    if (!newCallRecord.workerDob) missingFields.push('Technician Phone No');
    // if (!newCallRecord.workerStatus) missingFields.push('Worker status');
    if (!newCallRecord.workOrderNumber) missingFields.push('Work order number');
    if (!newCallRecord.checkIn) missingFields.push('Check in');
    // if (!newCallRecord.checkOut) missingFields.push('Check out');

    if (missingFields.length > 0) {
      // if (missingFields?.length === 1) {
      //   const missingFieldsString = missingFields.join(', ');
      //   toast.error(`${missingFieldsString} is required`);
      //   return;
      // } else {
      //   const missingFieldsString = missingFields.join(', ');
      //   toast.error(`${missingFieldsString} are required`);
      //   return;
      // }

      toast.error('Please fill all required fields')
    }

    if (missingFields?.length === 0) {
      dispatch(postCallRecord(jwtToken, { ...newCallRecord, corporateId: userId }));
    }

  }

  const handleEdit = () => {
    const missingFields = [];
    // if (!callRecord.phoneNumber) missingFields.push('Phone number');
    if (!callRecord.workerName) missingFields.push('Worker name');
    if (!callRecord.workerDob) missingFields.push('Technician phone no');
    if (!callRecord.workOrderNumber) missingFields.push('Work order number');
    if (!callRecord.checkIn) missingFields.push('Check in');
    // if (!callRecord.workerStatus) missingFields.push('Worker status');
    // if (!callRecord.checkOut) missingFields.push('Check out');

    if (missingFields.length > 0) {
      toast.error('Please fill all required fields')
    }

    if (missingFields?.length === 0) {
      dispatch(editCallRecord(jwtToken, currentCallRecordId, { ...callRecord, corporateId: userId }));
    }
  };

  const handleDelete = () => {
    if (currentCallRecordId) {
      dispatch(deleteCallRecord(jwtToken, currentCallRecordId))
      setSlideUpVisibleForDelete(false)
    }
  }

  const tableColumns = [
    // {
    //   dataField: 'index',
    //   text: '#',
    //   headerStyle: { backgroundColor: '#f0f0f073' },
    // },
    {
      dataField: 'workOrderNumber',
      text: 'Work Order No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return Number(a) - Number(b);
        }
        return Number(b) - Number(a);
      },
      formatter: (cell, row) => {
        return (
          <Link to={`/corporate/work-order/${row.worksiteId}/${row.workOrderNumber}`}>
            {row.workOrderNumber}
          </Link>
        );
      },
    },
    {
      dataField: 'workSite.storeNumber',
      text: 'Store No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return Number(a) - Number(b);
        }
        return Number(b) - Number(a);
      },
    },
    {
      dataField: 'workSite.storeName',
      text: 'Store Name',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    {
      dataField: 'workSite.state',
      text: 'State',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    {
      dataField: 'createdAt',
      text: 'Date',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      // sortCaret: (order, column) => {
      //   if (!order) return <span>&nbsp;&uarr;&darr;</span>;
      //   else if (order === 'asc') return <span>&nbsp;&uarr;</span>;
      //   else if (order === 'desc') return <span>&nbsp;&darr;</span>;
      //   return null;
      // },
      formatter: (cell) => {
        if (cell) {
          return new Date(cell).toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            // hour: '2-digit',
            // minute: '2-digit',
            // second: '2-digit',
            // hour12: true,
          });
        } else {
          return '';
        }
      },
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();

        if (order === 'asc') {
          return dateA - dateB; // Sort in ascending order
        } else {
          return dateB - dateA; // Sort in descending order
        }
      }
    },
    {
      dataField: 'checkIn',
      text: 'Check In Time',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();

        if (order === 'asc') {
          return dateA - dateB; // Sort in ascending order
        } else {
          return dateB - dateA; // Sort in descending order
        }
      },
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {new Date(cell).toLocaleString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: true,
                })}
              </span>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  flexDirection: 'row',
                  cursor: 'pointer',
                  marginTop: '7px',
                }}
                onClick={() => handleClickUrlOne(row)}
              >
                <div>
                  {row?.checkInRecordingUrl && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#007bff"
                      className="bi bi-play-circle"
                      viewBox="0 0 16 16"
                      style={{ marginRight: '5px' }}
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                    </svg>
                  )}
                </div>
                <div>
                  {row?.checkInCallDuration
                    ? `${row?.checkInCallDuration} sec`
                    : ''}
                </div>
              </div>
            </>
          );
        } else {
          return '';
        }
      },
    },
    {
      dataField: 'checkOut',
      text: 'Check Out Time',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      sortFunc: (a, b, order) => {
        const dateA = new Date(a).getTime();
        const dateB = new Date(b).getTime();

        if (order === 'asc') {
          return dateA - dateB; // Sort in ascending order
        } else {
          return dateB - dateA; // Sort in descending order
        }
      },
      formatter: (cell, row) => {
        if (cell) {
          return (
            <>
              <span>
                {new Date(cell).toLocaleString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: true,
                })}
              </span>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  flexDirection: 'row',
                  cursor: 'pointer',
                  marginTop: '7px',
                }}
                onClick={() => handleClickUrlTwo(row)}
              >
                <div>
                  {row?.checkOutRecordingUrl && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#007bff"
                      className="bi bi-play-circle"
                      viewBox="0 0 16 16"
                      style={{ marginRight: '5px' }}
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                    </svg>
                  )}
                </div>
                <div>
                  {row?.checkOutCallDuration
                    ? `${row?.checkOutCallDuration} sec`
                    : ''}
                </div>
              </div>
            </>
          );
        } else {
          return '';
        }
      },
    },
    {
      dataField: 'workHours',
      text: 'Hours Worked',
      sort: true,
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell) => {
        if (typeof cell !== 'string' || !cell.includes('.')) {
          return '';
        }
        const [hours, minutes] = cell.split('.');
        return `${hours}Hr ${minutes}Min`;
      },
    },
    {
      dataField: 'workerStatus',
      text: 'Status',
      headerStyle: { backgroundColor: '#f0f0f073' },
      formatter: (cell) => {
        if (typeof cell === 'string') {
          // Define the status to className map
          const statusClassMap = {
            'Completed': 'badge bg-success text-white fw-lighter p-2',
            'Additional Work Found': 'badge bg-primary text-white fw-lighter p-2',
            'Requires Part': 'badge bg-secondary fw-lighter p-2',
            'Requires Follow-up': 'badge bg-secondary fw-lighter p-2',
            'Requires Authorization': 'badge bg-danger text-white fw-lighter p-2',
          };

          // Get the capitalized status
          const capitalizedStatus = cell
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

          // Determine the appropriate className based on the status
          const className = statusClassMap[capitalizedStatus] || 'badge bg-dark'; // Default class
          return <span className={className}>{capitalizedStatus}</span>;
        }
        return cell;
      },
      // sort: true,
    },
    {
      dataField: 'phoneNumber',
      text: 'Store Phone No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      //   sort: true,
    },
    {
      dataField: 'workerName',
      text: 'Technician Name',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
    },
    {
      dataField: 'workerDob',
      text: 'Technician Phone No',
      headerStyle: { backgroundColor: '#f0f0f073' },
      //   sort: true,
    },
    {
      dataField: 'manualEntry',
      text: 'Manual Entry',
      csvExport: false,
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvExport: true,
      formatter: (cell) => {
        if (!cell) return '';
        if (!cell?.role) return '-';

        return (
          <>
            {`${cell.role.charAt(0).toUpperCase() + cell.role.slice(1).toLowerCase()}`}
            {cell.editedBy?.name ? ` - ${cell.editedBy.name}` : ''}
            <br />
            {cell.editedAt ? `(${formateDate(cell.editedAt)})` : ''}
          </>
        );
      },
      csvFormatter: (cell) => {
        if (!cell) return '';
        if (!cell?.role) return '-';
        return `${cell.role.charAt(0).toUpperCase() + cell.role.slice(1).toLowerCase()} - ${cell.editedBy.name} (${formateDate(cell.editedAt)})`;
      },
    },
    {
      text: 'Actions',
      headerStyle: { backgroundColor: '#f0f0f073' },
      csvExport: false,
      formatter: (cell, row) => (
        <div>
          <Button
            style={{ backgroundColor: 'green', borderColor: 'green' }}
            type="primary"
            utilclass="m-r-5"
            iconposition="center"
            onClick={() => handleViewClick(row)}
          >
            <i className="pg-icon">
              <RiShareBoxFill color="white" />
            </i>
          </Button>
          <Button
            type="primary"
            utilclass="m-r-5"
            iconposition="center"
            onClick={() => handleEditClick(row)}
          >
            <i className="pg-icon">edit</i>
            {/* <span className="">Edit</span> */}
          </Button>
          <Button
            type="danger"
            iconposition="center"
            onClick={() => handleDeleteClick(row)}
          >
            <i className="pg-icon">trash</i>
            {/* Delete */}
          </Button>
        </div>
      ),
    },
  ];

  const formateDate = (newDate) => {
    const originalDate = new Date(newDate);
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
      // timeZone: 'UTC'
    };

    const formattedDate = originalDate.toLocaleString('en-US', options)
      .replace(',', '');
    return formattedDate;
  }

  return (
    <div className="page-container">
      <div className="page-content-wrapper">
        <div className="content">
          <PageContainer className="container-fluid container-fixed-lg bg-white">
            <div className="card card-transparent">
              <div className="card-header d-flex flex-column justify-content-between ">
                <div className="d-flex flex-row justify-content-between mb-2">
                  <h3 className="text-primary no-margin text-uppercase">Visits</h3>
                  <div className='row gap-2 px-3'>
                    <button aria-label="" className="btn btn-primary btn-lg m-b-10 px-4" type="button" onClick={handleAddCallRecords}>
                      + Add Check-In / Check-Out
                    </button>
                    <div className="pl-2">
                      {csvProps && (
                        <ExportCSVButton {...csvProps}><i className="pg-icon m-r-10"><FiShare size={'15'} /></i> Export Visits CSV</ExportCSVButton>
                      )}
                    </div>
                  </div>
                </div>
                <div className="export-options-container">
                  <div className="exportOptions">



                    {slideUpVisibleForDelete ?
                      <SlideUpModal visible={slideUpVisibleForDelete} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForDelete(false)}>
                        <div className="modal-content-wrapper">
                          <div className="modal-content">
                            <div className="modal-top">
                              <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForDelete(false)} data-dismiss="modal" aria-hidden="true">
                                <i className="pg-icon">close</i>
                              </button>
                              <h5>Are you sure you want to delete?</h5>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-6">
                                  <button aria-label="" type="button" className="btn btn-secondary btn-block" onClick={() => setSlideUpVisibleForDelete(false)}>
                                    Cancel
                                  </button>
                                </div>
                                <div className="col-md-6">
                                  <button aria-label="" type="button" className="btn btn-danger btn-block" onClick={handleDelete}>
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SlideUpModal> : null}

                    {slideUpVisible ?
                      <SlideUpModal visible={slideUpVisible} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisible(false)}>
                        <div className="modal-content-wrapper">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5>Edit Call Record</h5>
                              <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisible(false)} data-dismiss="modal" aria-hidden="true">
                                <i className="pg-icon">close</i>
                              </button>
                            </div>
                            <div className="modal-body p-5">
                              <form role="form">
                                <div class="input-group flex-column">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label htmlFor='worksiteId'>Worksite<span style={{ color: 'red' }}>*</span></label>
                                      <input type="text" className="form-control w-100" placeholder='Update Worksite' value={workSitesForCorporateData.find(item => item.id === callRecord.worksiteId)?.storeName || ''} disabled name='worksiteId' id='worksiteId' />
                                    </div>

                                    <div className="col-md-6">
                                      <label for='phoneNumber'>Store Phone Number</label>
                                      <input type="text" className="form-control w-100" placeholder='Update Phone Number' value={callRecord.phoneNumber} onChange={handleInputChange} name='phoneNumber' id='phoneNumber' />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <label for='workerName' className='mt-3'>Technician Name<span style={{ color: 'red' }}>*</span></label>
                                      <input type="text" className="form-control w-100" placeholder='Update Worker Name' value={callRecord.workerName} onChange={handleInputChange} name='workerName' id='workerName' />
                                    </div>
                                    <div className="col-md-6">
                                      <label for='workerDob' className='mt-3'>Technician Phone No<span style={{ color: 'red' }}>*</span></label>
                                      <input type="text" className="form-control w-100" placeholder='Update Technician Phone No' value={callRecord.workerDob} onChange={handleInputChange} name='workerDob' id='workerDob' />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <label htmlFor='workerStatus' className='mt-3'>Worker Status</label>
                                      <GroupSelect
                                        inputId='workerStatus'
                                        value={callRecord.workerStatus}
                                        options={workerStatusOptions}
                                        onChange={(selectedOption) => handleInputChange({ name: 'workerStatus' }, selectedOption?.value)}
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <label for='workOrderNumber' className='mt-3'>Work Order Number<span style={{ color: 'red' }}>*</span></label>
                                      <input type="text" className="form-control w-100" placeholder='Update Work Order Number' value={callRecord.workOrderNumber} onChange={handleInputChange} name='workOrderNumber' id='workOrderNumber' />
                                    </div>
                                  </div>

                                  <label for='checkIn' className='mt-3'>Check In<span style={{ color: 'red' }}>*</span></label>
                                  <DatePicker
                                    onChange={(selectedOption) => handleInputChange({ name: 'checkIn' }, selectedOption)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    timeCaption="Time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    placeholderText="Select date and time"
                                    className="form-control w-100"
                                    selected={callRecord.checkIn}
                                    name='checkIn'
                                    id='checkIn'
                                  />

                                  <label for='checkOut' className='mt-3'>Check Out</label>
                                  <DatePicker
                                    onChange={(selectedOption) => handleInputChange({ name: 'checkOut' }, selectedOption)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    timeCaption="Time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    placeholderText="Select date and time"
                                    className="form-control w-100"
                                    selected={callRecord.checkOut}
                                    name='checkOut'
                                    id='checkOut'
                                  />

                                </div>

                              </form>
                            </div>

                            <div className='modal-footer'>
                              <button aria-label="" type="button" className="btn btn-primary" onClick={handleEdit}>
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </SlideUpModal>
                      : null
                    }

                    {slideUpVisibleForCreate ?
                      <SlideUpModal visible={slideUpVisibleForCreate} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForCreate(false)}>
                        <div className="modal-content-wrapper">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5>Add Call Record</h5>
                              <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForCreate(false)} data-dismiss="modal" aria-hidden="true">
                                <i className="pg-icon">close</i>
                              </button>
                            </div>
                            <div className="modal-body px-5 py-5">
                              <form role="form">
                                <div class="input-group flex-column">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label htmlFor='worksiteId'>Worksite<span style={{ color: 'red' }}>*</span></label>
                                      <GroupSelect
                                        inputId='worksiteId'
                                        value={newCallRecord.worksiteId}
                                        options={worksiteOptions}
                                        onChange={(selectedOption) => newCallRecordInputChange({ name: 'worksiteId' }, selectedOption?.value)}
                                      />
                                    </div>

                                    <div className="col-md-6">
                                      <label for='phoneNumber'>Store Phone Number</label>
                                      <input type="text" className="form-control w-100" placeholder='Phone Number' value={newCallRecord.phoneNumber} onChange={newCallRecordInputChange} name='phoneNumber' id='phoneNumber' />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <label for='workerName' className='mt-3'>Technician Name<span style={{ color: 'red' }}>*</span></label>
                                      <input type="text" className="form-control w-100" placeholder='Worker Name' value={newCallRecord.workerName} onChange={newCallRecordInputChange} name='workerName' id='workerName' />
                                    </div>
                                    <div className="col-md-6">
                                      <label for='workerDob' className='mt-3'>Technician Phone No<span style={{ color: 'red' }}>*</span></label>
                                      <input type="text" className="form-control w-100" placeholder='Technician Phone No' value={newCallRecord.workerDob} onChange={newCallRecordInputChange} name='workerDob' id='workerDob' />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-6">
                                      <label htmlFor='workerStatus' className='mt-3'>Worker Status</label>
                                      <GroupSelect
                                        inputId='workerStatus'
                                        value={newCallRecord.workerStatus}
                                        options={workerStatusOptions}
                                        onChange={(selectedOption) => newCallRecordInputChange({ name: 'workerStatus' }, selectedOption?.value)}
                                      />
                                    </div>

                                    <div className="col-md-6">
                                      <label for='workOrderNumber' className='mt-3'>Work Order Number<span style={{ color: 'red' }}>*</span></label>
                                      <input type="text" className="form-control w-100" placeholder='Work Order Number' value={newCallRecord.workOrderNumber} onChange={newCallRecordInputChange} name='workOrderNumber' id='workOrderNumber' />
                                    </div>
                                  </div>

                                  <label for='checkIn' className='mt-3'>Check In<span style={{ color: 'red' }}>*</span></label>
                                  <DatePicker
                                    onChange={(selectedOption) => newCallRecordInputChange({ name: 'checkIn' }, selectedOption)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    timeCaption="Time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    placeholderText="Select date and time"
                                    className="form-control w-100"
                                    selected={newCallRecord.checkIn}
                                    name='checkIn'
                                    id='checkIn'
                                  />

                                  <label for='checkOut' className='mt-3'>Check Out</label>
                                  <DatePicker
                                    onChange={(selectedOption) => newCallRecordInputChange({ name: 'checkOut' }, selectedOption)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    timeCaption="Time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    placeholderText="Select date and time"
                                    className="form-control w-100"
                                    selected={newCallRecord.checkOut}
                                    name='checkOut'
                                    id='checkOut'
                                  />


                                </div>

                              </form>
                            </div>
                            <div className='modal-footer'>
                              <button aria-label="" type="button" className="btn btn-primary" onClick={handleCreate}>
                                Add
                              </button>
                            </div>
                          </div>
                        </div>
                      </SlideUpModal>
                      : null
                    }

                    {audioSlideUpVisible ? (
                      <SlideUpModal visible={audioSlideUpVisible} width={'400'} effect="fadeInUp" onClickAway={() => closeModal()}>
                        <div className="modal-content-wrapper">
                          <div className="modal-content">
                            <div className="modal-top">
                              <button
                                aria-label=""
                                type="button"
                                className="close"
                                onClick={() => closeModal()}
                                aria-hidden="true"
                              >
                                <i className="pg-icon">close</i>
                              </button>
                              <h4 className="text-primary no-margin text-uppercase text-center">{name}</h4>
                            </div>
                            {audioUrl?.length > 0 ? (
                              <div className="modal-body">
                                <form role="form">
                                  <div className="form-group-attached">
                                    <div className="row justify-content-center">
                                      <audio controls style={{ margin: "0", height: '40px' }}>
                                        <source src={audioUrl} type="audio/mpeg" />
                                      </audio>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            ) : (
                              <div className="modal-body">
                                <div className="row justify-content-center">
                                  <div class="spinner-grow" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </SlideUpModal>
                    ) : null}

                    {slideUpVisibleForViewData ?
                      <SlideUpModal visible={slideUpVisibleForViewData} width={slideUpWidth} effect="fadeInUp" onClickAway={() => setSlideUpVisibleForViewData(false)}>
                        <div className="modal-content-wrapper">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5>Call Record Details</h5>
                              <button aria-label="" type="button" className="close" onClick={() => setSlideUpVisibleForViewData(false)} data-dismiss="modal" aria-hidden="true">
                                <i className="pg-icon">close</i>
                              </button>
                            </div>
                            <div className="modal-body py-3 h100vh">
                              <div className="row">

                                <div className="col-md-6">
                                  {/* <p><strong>ID:</strong> {viewData.id}</p> */}

                                  <div className='social-card share w-100 mb-3'>
                                    <div class="pgn-message card-header">
                                      <div>
                                        <p>Technician Name</p>
                                        {viewData.workerName ?
                                          <p class="bold mb-0">{viewData.workerName}</p> :
                                          <p class="bold mb-0 ml-1">{"-"}</p>
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  <div className='social-card share w-100 mb-3'>
                                    <div class="pgn-message card-header">
                                      <div>
                                        <p>Technician Phone No</p>
                                        {viewData.workerDob ?
                                          <p class="bold mb-0">{viewData.workerDob}</p> :
                                          <p class="bold mb-0 ml-1">{"-"}</p>
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  <div className='social-card share w-100 mb-3'>
                                    <div class="pgn-message card-header">
                                      <div>
                                        <p>Check In</p>
                                        {viewData.checkIn ?
                                          <p class="bold mb-0">{formateDate(viewData.checkIn)}</p> :
                                          <p class="bold mb-0 ml-1">{"-"}</p>
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  <div className='social-card share w-100 mb-3'>
                                    <div class="pgn-message card-header">
                                      <div>
                                        <p>Check In Call Duration</p>
                                        {viewData.checkInCallDuration ?
                                          <p class="bold mb-0">{`${viewData?.checkInCallDuration} sec` || 'N/A'}</p> :
                                          <p class="bold mb-0 ml-1">{"-"}</p>
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  <div className='social-card share w-100 mb-3'>
                                    <div class="pgn-message card-header">
                                      <div>
                                        <p>Created At</p>
                                        <p class="bold mb-0">{formateDate(viewData?.createdAt)}</p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className='social-card share w-100 mb-3'>
                                    <div class="pgn-message card-header">
                                      <div>
                                        <p>Store Phone Number</p>
                                        {viewData.phoneNumber ?
                                          <p class="bold mb-0">{viewData.phoneNumber}</p> :
                                          <p class="bold mb-0 ml-1">{"-"}</p>
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  <div className='social-card share w-100 mb-3'>
                                    <div class="pgn-message card-header">
                                      <div>
                                        <p>Worker Status</p>
                                        {viewData.workerStatus ?
                                          <p class="bold mb-0">{viewData.workerStatus}</p> :
                                          <p class="bold mb-0 ml-1">{"-"}</p>
                                        }
                                      </div>
                                    </div>
                                  </div>

                                </div>

                                <div className="col-md-6">
                                  <div className='social-card share w-100 mb-3'>
                                    <div class="pgn-message card-header">
                                      <div>
                                        <p>Worksite</p>
                                        {viewData.workOrderNumber ?
                                          <p class="bold mb-0">{workSitesForCorporateData.find(item => item.id === viewData.worksiteId)?.storeName || ''}</p> :
                                          <p class="bold mb-0 ml-1">{"-"}</p>
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  <div className='social-card share w-100 mb-3'>
                                    <div class="pgn-message card-header">
                                      <div>
                                        <p>Work Order Number</p>
                                        {viewData.workOrderNumber ?
                                          <p class="bold mb-0">{viewData.workOrderNumber}</p> :
                                          <p class="bold mb-0 ml-1">{"-"}</p>
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  <div className='social-card share w-100 mb-3'>
                                    <div class="pgn-message card-header">
                                      <div>
                                        <p>Check Out</p>
                                        {viewData.checkOut ?
                                          <p class="bold mb-0">{formateDate(viewData.checkOut)}</p> :
                                          <p class="bold mb-0 ml-1">{"-"}</p>
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  <div className='social-card share w-100 mb-3'>
                                    <div class="pgn-message card-header">
                                      <div>
                                        <p>Check Out Call Duration</p>
                                        {viewData.checkOutCallDuration ?
                                          <p class="bold mb-0">{`${viewData?.checkOutCallDuration} sec` || 'N/A'}</p> :
                                          <p class="bold mb-0 ml-1">{"-"}</p>
                                        }
                                      </div>
                                    </div>
                                  </div>

                                  <div className='social-card share w-100 mb-3'>
                                    <div class="pgn-message card-header">
                                      <div>
                                        <p>Updated At</p>
                                        <p class="bold mb-0">{formateDate(viewData?.updatedAt)}</p>
                                      </div>
                                    </div>
                                  </div>

                                  {viewData && viewData?.manualEntry?.role && <div className='social-card share w-100 mb-3'>
                                    <div className="pgn-message card-header">
                                      <div>
                                        <p>Manual Entry</p>
                                        <p className="bold mb-0"><span className='text-capitalize'>{viewData?.manualEntry?.role}</span> - {viewData?.manualEntry?.editedBy?.name} <br /> ({formateDate(viewData?.manualEntry?.editedAt)})</p>
                                      </div>
                                    </div>
                                  </div>}

                                  {/* <p><strong>Check In Recording URL:</strong> {viewData?.checkInRecordingUrl || 'N/A'}</p> */}
                                  {/* <p><strong>Check Out Recording URL:</strong> {viewData?.checkOutRecordingUrl || 'N/A'}</p> */}
                                  {/* <p><strong>Check In Call SID:</strong> {viewData?.checkInCallSID || 'N/A'}</p>
                                    <p><strong>Check Out Call SID:</strong> {viewData?.checkOutCallSID || 'N/A'}</p>
                                    <p><strong>Check In Recording SID:</strong> {viewData?.checkInRecordingSID || 'N/A'}</p>
                                    <p><strong>Check Out Recording SID:</strong> {viewData?.checkOutRecordingSID || 'N/A'}</p> */}
                                  {/* <p><strong>Worksite:</strong> {viewData?.worksiteId}</p> */}

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SlideUpModal>
                      : null
                    }
                  </div>
                </div>
              </div>

              <div className='d-flex flex-row justify-content-start'>

                <div className="input-group p-l-0" style={{ width: '300px'}}>
                  <span className="input-group-text input-date-icon-pre">
                    <i className="pg-icon">search</i>
                  </span>
                  <input
                    type="text"
                    name="workerName"
                    className="form-control py-2"
                    placeholder="Search by technician name"
                    value={filters.workerName}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="input-group p-l-0" style={{width: '400px', marginLeft :"15px"}}>
                  <span className="input-group-text input-date-icon-pre">
                    <i className="pg-icon">search</i>
                  </span>
                  <input
                    type="text"
                    className="form-control py-2"
                    placeholder="Search by technician phone number"
                    name='technicianPhoneNumber'
                    value={filters.technicianPhoneNumber}
                    onChange={handleSearchChange}
                  />
                </div>


                <div className="input-group col-md-2 p-l-0" style={{marginLeft: "15px"}}>
                  <GroupSelect
                    placeholder='Status'
                    inputId='worksiteId'
                    value={workerStatus}
                    options={workerStatusFilterOptions}
                    onChange={(selectedOption) => setWorkerStatus(selectedOption?.value)}
                  />
                </div>

                <div className="input-group col-md-2 p-l-0">
                  <div className="input-group">
                    <GroupSelect
                      placeholder='Store Name'
                      inputId="worksiteId"
                      value={worksiteId}
                      options={worksiteOptionsForFilter}
                      onChange={(selectedOption) => setWorksiteId(selectedOption?.value)}
                    />
                  </div>
                </div>

                <div className="col-4 col-md-2 col-lg-3 mb-3 mb-md-0 px-1">
                  <DateRangePicker
                    startDate={startDateRange}
                    endDate={endDateRange}
                    showTimeSelect
                    onApply={handleDateRange}
                  >
                    <div className="input-group col-md-5">
                      <span className="input-group-text input-date-icon-pre">
                        <i className="pg-icon">calendar</i>
                      </span>
                      <input
                        type="text"
                        name="reservation"
                        id="daterangepicker"
                        className="form-control py-2"
                        value={`${moment(startDateRange).format("MM/DD/YYYY")} - ${moment(endDateRange).format("MM/DD/YYYY")}`} // Only date format
                        onChange={() => { }}
                      />
                    </div>
                  </DateRangePicker>
                </div>
              </div>

              {!isLoading ? <div className="card-body custable">
                {callRecordsWithIndex?.length > 0 ? (
                  <ToolkitProvider
                    keyField="id"
                    data={exportCallRecordsData}
                    columns={tableColumns}
                    exportCSV={{
                      fileName: 'callRecords-data.csv',
                      ignoreHeader: false,
                      noAutoBOM: false,
                    }}
                  >
                    {(props) => {
                      csvPropsRef.current = props.csvProps;
                      if (!csvProps) {
                        setCsvProps(props.csvProps);
                      }
                      return (
                        <React.Fragment>
                          <BootstrapTable
                            {...props.baseProps}
                            hover={true}
                            bootstrap4
                            bordered={false}
                            condensed={true}
                            striped={true}
                            pagination={paginationFactory(options)}
                          // cellEdit={cellEditFactory({
                          //   mode: 'click',
                          //   blurToSave: true,
                          // })}
                          />
                        </React.Fragment>
                      )
                    }}
                  </ToolkitProvider>
                ) : (
                  <NoData />
                )}
              </div> : <Loader />}
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  );
};

export default CallRecords;
