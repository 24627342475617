import AdminAuthActions from '../redux/actions/AdminAuthAction';
import apiSignUp from '../services/adminSignUp';

export const adminLogin = (authProps) => {
  return async (dispatch) => {
    await apiSignUp
      .post('admin-auth/admin-login', authProps)
      .then((res) => {
        dispatch({
          type: AdminAuthActions.USER_LOGGED_DATA,
          payload: res?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminAuthActions.USER_LOGGED_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const corporateLogin = (authProps) => {
  return async (dispatch) => {
    await apiSignUp
      .post('corporate-auth/corporate-login', authProps)
      .then((res) => {
        dispatch({
          type: AdminAuthActions.USER_LOGGED_DATA,
          payload: res?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminAuthActions.USER_LOGGED_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const worksiteLogin = (authProps) => {
  return async (dispatch) => {
    await apiSignUp
      .post('worksite-auth/worksite-login', authProps)
      .then((res) => {
        dispatch({
          type: AdminAuthActions.USER_LOGGED_DATA,
          payload: res?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminAuthActions.USER_LOGGED_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const corporateForgotPassword = (authProps) => {
  return async (dispatch) => {
    await apiSignUp
      .post('corporate-auth/forgot-password', authProps)
      .then((res) => {
        dispatch({
          type: AdminAuthActions.USER_RESET_TOKEN,
          payload: res?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminAuthActions.USER_RESET_TOKEN_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const WorksiteForgotPassword = (authProps) => {
  return async (dispatch) => {
    await apiSignUp
      .post('worksite-auth/forgot-password', authProps)
      .then((res) => {
        dispatch({
          type: AdminAuthActions.USER_RESET_TOKEN,
          payload: res?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminAuthActions.USER_RESET_TOKEN_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const worksiteChangePassword = (authProps) => {
  return async (dispatch) => {
    await apiSignUp
      .post('worksite-auth/reset-password', authProps)
      .then((res) => {
        dispatch({
          type: AdminAuthActions.USER_RESET_TOKEN_VALIDATION,
          payload: res?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminAuthActions.USER_RESET_TOKEN_VALIDATION_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const corporateChangePassword = (authProps) => {
  return async (dispatch) => {
    await apiSignUp
      .post('corporate-auth/reset-password', authProps)
      .then((res) => {
        dispatch({
          type: AdminAuthActions.USER_RESET_TOKEN_VALIDATION,
          payload: res?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AdminAuthActions.USER_RESET_TOKEN_VALIDATION_ERROR,
          payload: error?.response?.data,
        });
      });
  };
};

export const setUserType = (userType) => {
  return (dispatch) => {
    dispatch({ type: AdminAuthActions.USER_TYPE_DATA, payload: userType });
  }
}

export const setUserId = (userId) => {
  return (dispatch) => {
    dispatch({ type: AdminAuthActions.USER_ID_DATA, payload: userId });
  }
}

export const resetUserData = () => {
  return (dispatch) => {
    dispatch({ type: AdminAuthActions.USER_LOGGED_DATA, payload: [] });
  }
}

export const resetUserErrorData = () => {
  return (dispatch) => {
    dispatch({ type: AdminAuthActions.USER_LOGGED_ERROR, payload: {} });
  };
};

export const resetSetUserType = () => {
  return (dispatch) => {
    dispatch({ type: AdminAuthActions.USER_TYPE_DATA, payload: '' });
  }
}

export const resetPasswordLinkTokenData = () => {
  return (dispatch) => {
    dispatch({ type: AdminAuthActions.USER_RESET_TOKEN, payload: {} });
  }
}

export const resetPasswordLinkTokenError = () => {
  return (dispatch) => {
    dispatch({ type: AdminAuthActions.USER_RESET_TOKEN_ERROR, payload: {} });
  }
}

export const resetTokenData = () => {
  return (dispatch) => {
    dispatch({ type: AdminAuthActions.USER_RESET_TOKEN_VALIDATION, payload: {} });
  }
}

export const resetTokenDataError = () => {
  return (dispatch) => {
    dispatch({ type: AdminAuthActions.USER_RESET_TOKEN_VALIDATION_ERROR, payload: {} });
  }
}

export const resetSetUserId = () => {
  return (dispatch) => {
    dispatch({ type: AdminAuthActions.USER_TYPE_DATA, payload: '' });
  }
}
