import WorkSiteDataActions from '../actions/WorkSiteDataAction'

const initialState = {
  workSitesData: [],
  getWorkSiteRecordsError: {},

  getWorksiteWorkOrderNumbersData: [],
  getWorksiteWorkOrderNumbersError: {},
  getWorksiteCallRecordsData: [],
  getWorksiteCallRecordsError: {},
  getWorksitePhoneNumbersData: [],
  getWorksitePhoneNumbersError: {},
  getTollfreeNumber: {},
  getTollfreeNumberError: {},
  getCallRecordsByWorkOrderNumberData: {},
  getCallRecordsByWorkOrderNumberError: {},
  getWorksiteCheckinRecords: [],
  getWorksiteCheckinRecordsError: {},

  getWorksiteCompletedVisitsData: [],
  getWorksiteCompletedVisitsError: {},

  worksiteManagerData: {},
  worksiteManagerError: {},

  postWorkSiteData: {},
  postWorkSiteError: {},
  postCallRecordData: {},
  postCallRecordError: {},
  postPhoneNumberData: {},
  postPhoneNumberError: {},
  postworkOrderNumberData: {},
  postworkOrderNumberError: {},
  postWorkSiteWorkOrderNumbersData: {},
  postWorkSiteWorkOrderNumbersError: {},

  editWorkSiteData: {},
  editWorkSiteError: {},
  editCallRecordData: {},
  editCallRecordError: {},
  editPhoneNumberData: {},
  editPhoneNumberError: {},
  editworkOrderNumberData: {},
  editworkOrderNumberError: {},
  editWorksitePasswordData: {},
  editWorksitePasswordError: {},

  deleteWorkSiteData: {},
  deleteWorkSiteError: {},
  deleteCallRecordData: {},
  deleteCallRecordError: {},
  deletePhoneNumberData: {},
  deletePhoneNumberError: {},
  deleteworkOrderNumberData: {},
  deleteworkOrderNumberError: {},
};

export default function WorkSiteData(state = initialState, action) {
  switch (action.type) {
    // GET Method
    case WorkSiteDataActions.GET_ALL_WORK_SITES_DATA:
      return { ...state, workSitesData: action?.payload };
    case WorkSiteDataActions.GET_ALL_WORK_SITES_ERROR:
      return { ...state, getWorkSiteRecordsError: action?.payload };

    case WorkSiteDataActions.GET_WORK_SITE_WORK_ORDER_NUMBERS_DATA:
      return { ...state, getWorksiteWorkOrderNumbersData: action?.payload };
    case WorkSiteDataActions.GET_WORK_SITE_WORK_ORDER_NUMBERS_ERROR:
      return { ...state, getWorksiteWorkOrderNumbersError: action?.payload };

    case WorkSiteDataActions.GET_WORK_SITE_CALL_RECORDS_DATA:
      return { ...state, getWorksiteCallRecordsData: action?.payload };
    case WorkSiteDataActions.GET_WORK_SITE_CALL_RECORDS_ERROR:
      return { ...state, getWorksiteCallRecordsError: action?.payload };

    case WorkSiteDataActions.GET_WORK_SITE_COMPLETED_VISITS_DATA:
      return { ...state, getWorksiteCompletedVisitsData: action?.payload };
    case WorkSiteDataActions.GET_WORK_SITE_COMPLETED_VISITS_ERROR:
      return { ...state, getWorksiteCompletedVisitsError: action?.payload };

    case WorkSiteDataActions.GET_WORK_SITE_PHONE_NUMBERS_DATA:
      return { ...state, getWorksitePhoneNumbersData: action?.payload };
    case WorkSiteDataActions.GET_WORK_SITE_PHONE_NUMBERS_ERROR:
      return { ...state, getWorksitePhoneNumbersError: action?.payload };

    case WorkSiteDataActions.GET_WORK_SITE_MANAGER_DATA:
      return { ...state, worksiteManagerData: action?.payload };
    case WorkSiteDataActions.GET_WORK_SITE_MANAGER_ERROR:
      return { ...state, worksiteManagerError: action?.payload };

    case WorkSiteDataActions.GET_WORK_SITE_CHECKIN_RECORDS:
      return { ...state, getWorksiteCheckinRecords: action?.payload };
    case WorkSiteDataActions.GET_WORK_SITE_CHECKIN_RECORDS_ERROR:
      return { ...state, getWorksiteCheckinRecordsError: action?.payload };

    case WorkSiteDataActions.GET_WORKSITE_TOLL_FREE_NUMBER:
      return { ...state, getTollfreeNumber: action?.payload };
    case WorkSiteDataActions.GET_WORKSITE_TOLL_FREE_NUMBER_ERROR:
      return { ...state, getTollfreeNumberError: action?.payload };

    case WorkSiteDataActions.GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_DATA:
      return { ...state, getCallRecordsByWorkOrderNumberData: action?.payload };
    case WorkSiteDataActions.GET_CALL_RECORD_BY_WORK_ORDER_NUMBER_ERROR:
      return { ...state, getCallRecordsByWorkOrderNumberError: action?.payload };

    // POST Method
    case WorkSiteDataActions.POST_WORK_SITE_DATA:
      return { ...state, postWorkSiteData: action?.payload };
    case WorkSiteDataActions.POST_WORK_SITE_ERROR:
      return { ...state, postWorkSiteError: action?.payload };
    case WorkSiteDataActions.POST_CALL_RECORD_DATA:
      return { ...state, postCallRecordData: action?.payload };
    case WorkSiteDataActions.POST_CALL_RECORD_ERROR:
      return { ...state, postCallRecordError: action?.payload };
    case WorkSiteDataActions.POST_PHONE_NUMBER_DATA:
      return { ...state, postPhoneNumberData: action?.payload };
    case WorkSiteDataActions.POST_PHONE_NUMBER_ERROR:
      return { ...state, postPhoneNumberError: action?.payload };
    case WorkSiteDataActions.POST_WORK_ORDER_NUMBER_DATA:
      return { ...state, postWorkOrderNumberData: action?.payload };
    case WorkSiteDataActions.POST_WORK_ORDER_NUMBER_ERROR:
      return { ...state, postWorkOrderNumberError: action?.payload };

    case WorkSiteDataActions.POST_WORK_SITE_WORK_ORDER_NUMBERS_USING_CSV_DATA:
      return { ...state, postWorkSiteWorkOrderNumbersData: action?.payload };
    case WorkSiteDataActions.POST_WORK_SITE_WORK_ORDER_NUMBERS_USING_CSV_ERROR:
      return { ...state, postWorkSiteWorkOrderNumbersError: action?.payload };

    // EDIT Method
    case WorkSiteDataActions.EDIT_WORK_SITE_DATA:
      return { ...state, editWorkSiteData: action?.payload };
    case WorkSiteDataActions.EDIT_WORK_SITE_ERROR:
      return { ...state, editWorkSiteError: action?.payload };
    case WorkSiteDataActions.EDIT_CALL_RECORD_DATA:
      return { ...state, editCallRecordData: action?.payload };
    case WorkSiteDataActions.EDIT_CALL_RECORD_ERROR:
      return { ...state, editCallRecordError: action?.payload };
    case WorkSiteDataActions.EDIT_PHONE_NUMBER_DATA:
      return { ...state, editPhoneNumberData: action?.payload };
    case WorkSiteDataActions.EDIT_PHONE_NUMBER_ERROR:
      return { ...state, editPhoneNumberError: action?.payload };
    case WorkSiteDataActions.EDIT_WORK_ORDER_NUMBER_DATA:
      return { ...state, editWorkOrderNumberData: action?.payload };
    case WorkSiteDataActions.EDIT_WORK_ORDER_NUMBER_ERROR:
      return { ...state, editWorkOrderNumberError: action?.payload };

    case WorkSiteDataActions.UPDATE_CALL_RECORD_DATA:
      return { ...state, updateCallRecordData: action?.payload };
    case WorkSiteDataActions.UPDATE_CALL_RECORD_ERROR:
      return { ...state, updateCallRecordError: action?.payload };

    case WorkSiteDataActions.EDIT_WORK_SITE_PASSWORD_DATA:
      return { ...state, editWorksitePasswordData: action?.payload };
    case WorkSiteDataActions.EDIT_WORK_SITE_PASSWORD_ERROR:
      return { ...state, editWorksitePasswordError: action?.payload };

    // DELETE Method
    case WorkSiteDataActions.DELETE_WORK_SITE_DATA:
      return { ...state, deleteWorkSiteData: action?.payload };
    case WorkSiteDataActions.DELETE_WORK_SITE_ERROR:
      return { ...state, deleteWorkSiteError: action?.payload };
    case WorkSiteDataActions.DELETE_CALL_RECORD_DATA:
      return { ...state, deleteCallRecordData: action?.payload };
    case WorkSiteDataActions.DELETE_CALL_RECORD_ERROR:
      return { ...state, deleteCallRecordError: action?.payload };
    case WorkSiteDataActions.DELETE_PHONE_NUMBER_DATA:
      return { ...state, deletePhoneNumberData: action?.payload };
    case WorkSiteDataActions.DELETE_PHONE_NUMBER_ERROR:
      return { ...state, deletePhoneNumberError: action?.payload };
    case WorkSiteDataActions.DELETE_WORK_ORDER_NUMBER_DATA:
      return { ...state, deleteWorkOrderNumberData: action?.payload };
    case WorkSiteDataActions.DELETE_WORK_ORDER_NUMBER_ERROR:
      return { ...state, deleteWorkOrderNumberError: action?.payload };

    default:
      return { ...state };
  }
}
